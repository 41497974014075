import axios from "@/axios"

const base = "/api/training/"

class PreparacionService {
	async getCounterTrainings({ start_date, end_date, specimen_id }) {
		const { data } = await axios.get(`${base}counter-training`, { params: { start_date, end_date, specimen_id } })
		return data
	}
	async getSpecimensForTraining(params) {
		const { data } = await axios.get(`${base}get-specimens-actives-to-training`, { params })
		return data
	}
	async getTrainings({
		status_id,
		start_date,
		end_date,
		search,
		page,
		perpage,
		block,
		specimen_id,
		qualities,
		defects,
		average_min,
		average_max,
		average_training_min,
		average_training_max,
		type_filter_date,
		filter_start_date,
		filter_end_date,
		preparador_id,
	}) {
		const { data } = await axios.get(`${base}`, {
			params: {
				status_id,
				start_date,
				end_date,
				search,
				page,
				perpage,
				block,
				specimen_id,
				qualities,
				defects,
				average_min,
				average_max,
				average_training_min,
				average_training_max,
				type_filter_date,
				filter_start_date,
				filter_end_date,
				preparador_id,
			},
		})
		return data
	}

	async getTrips({
		status_id,
		start_date,
		end_date,
		search,
		page,
		perpage,
		block,
		specimen_id,
		qualities,
		defects,
		average_min,
		average_max,
		average_training_min,
		average_training_max,
		type_filter_date,
		filter_start_date,
		filter_end_date,
		preparador_id,
	}) {
		const { data } = await axios.get(`${base}get-trip`, {
			params: {
				start_date,
				end_date,
				search,
				page,
				perpage,
				block,
				specimen_id,
				qualities,
				defects,
				average_min,
				average_max,
				average_training_min,
				average_training_max,
				type_filter_date,
				filter_start_date,
				filter_end_date,
				preparador_id,
			},
		})
		return data
	}
	async insertTraining({ specimens, block_id }) {
		const { data } = await axios.post(`${base}insert-specimen-to-training`, { specimens, block_id })
		return data
	}
	async updateTraining({ training_routine_id, rating }) {
		const { data } = await axios.put(`${base}update-training-routine`, { training_routine_id, rating })
		return data
	}
	async insertWeight({ specimens }) {
		const { data } = await axios.post(`${base}insert-weight-massive-training`, { specimens })
		return data
	}
	async insertVitamin({ trainings }) {
		const { data } = await axios.post(`${base}insert-vitamin`, { trainings })
		return data
	}
	async insertDeworn({ trainings }) {
		const { data } = await axios.post(`${base}de-worn/store`, { trainings })
		return data
	}
	async insertRoutine({ time, rating, comment, training_id, training_physical }) {
		const { data } = await axios.post(`${base}insert-routine`, {
			time,
			rating,
			comment,
			training_id,
			training_physical,
		})
		return data
	}
	async insertTope(topes) {
		// const { data } = await axios.post(`/api/training/insert-tope`, form);
		// return data;
		try {
			const { data } = await axios.post(`${base}insert-tope`, {
				topes,
			})
			return {
				status: true,
				message: "Guardado de forma correcta",
			}
		} catch (error) {
			return {
				status: false,
				message: "Error de servidor",
			}
		}
	}
	async insertReviewTope({
		qualities,
		defects,
		rating_local,
		specimen_id,
		rival_id,
		defects_rival,
		qualities_rival,
		rating_rival,
		confrontation_id,
	}) {
		const { data } = await axios.post(`${base}qualify-tope-admin`, {
			qualities,
			defects,
			rating_local,
			specimen_id,
			rival_id,
			defects_rival,
			qualities_rival,
			rating_rival,
			confrontation_id,
		})
		return data
	}
	async changeStatus(form) {
		const { data } = await axios.post(`${base}change-to-status-training/${form.get("training_id")}`, form)
		return data
	}
	async changeBlock({ training_id, block_id }) {
		const { data } = await axios.put(`${base}change-block-training/${training_id}`, {
			block_id,
		})
		return data
	}
	async getAvailableVitamins() {
		const { data } = await axios.get(`${base}get-training-vitamins`)
		return data
	}
	async getRivals({ type_rival, search }) {
		const { data } = await axios.get(`${base}select-rival-to-tope`, { params: { type_rival, search } })
		return data
	}
	async getTrackingWeights({ training_id }) {
		const { data } = await axios.get(`${base}get-weight-to-training`, {
			params: { training_id },
		})
		return data
	}
	async getTrackingVitamins({ training_id }) {
		const { data } = await axios.get(`${base}get-training-vitamins`, { params: { training_id } })
		return data
	}
	async getTrackingRoutines({ training_id }) {
		const { data } = await axios.get(`${base}get-routines`, { params: { training_id } })
		return data
	}
	async getTrackingTope({ training_id }) {
		const { data } = await axios.get(`${base}get-tope-by-training`, { params: { training_id } })
		return data
	}

	async getTopesBySpecimenTracking({ specimen_id }) {
		const { data } = await axios.get(`${base}get-topes-by-specimen`, { params: { specimen_id } })
		return data
	}

	async getTrackingDeworn({ training_id }) {
		const { data } = await axios.get(`${base}de-worn`, { params: { training_id } })
		return data
	}
	async getTrackingPreparation({ training_id }) {
		const { data } = await axios.get(`${base}get-training-tracking/${training_id}`)
		return data
	}

	async storeVideo(body) {
		try {
			const { data } = await axios.post(`${base}store-video`, body)
			return {
				status: true,
				message: "Video subido de forma correcta",
			}
		} catch (error) {
			return {
				status: false,
				message: "Error de servidor",
			}
		}
	}

	async getTrackingCharacteristic({ training_id, characteristics_id, type, specimen_id }) {
		const { data } = await axios.get(`${base}get-tracking-topes-users`, {
			params: { training_id, characteristics_id, type, specimen_id },
		})
		return data
	}

	async getCountTopesPending(params) {
		const { data } = await axios.get(`${base}get-count-topes-pending`, { params })
		return data
	}

	async validActiveToVideoTope({ id }) {
		const { data } = await axios.get(`${base}valid-active-to-video-tope/${id}`)
		return data
	}

	async validActiveToVideoById({ id }) {
		const { data } = await axios.get(`${base}valid-active-to-video-by-id/${id}`)
		return data
	}

	async getDashbordResume({ id }) {
		const { data } = await axios.get(`${base}scores/get-resumen-training-score-by-specimen/${id}`)
		return data
	}
	async getDashbordDetail({ id }) {
		const { data } = await axios.get(`${base}scores/get-scores-specimen-id/${id}`)
		return data
	}
	async getRatingTracking({ specimen_id, confrontation_id }) {
		const { data } = await axios.get(`${base}get-scores-specimen-by-confrontation`, {
			params: { specimen_id, confrontation_id },
		})
		return data
	}
	async getSpecimensToActive({ search }) {
		const { data } = await axios.get(`${base}get-specimens-to-active`, {
			params: { search },
		})
		return data
	}

	async insertPreparationMasive({ specimens, date }) {
		const { data } = await axios.post(`${base}insert-preparation-masive`, {
			specimens,
			date,
		})
		return data
	}

	async getTimeline({ specimen_id, training_id, perpage, page }) {
		const { data } = await axios.get(`${base}get-timeline`, {
			params: { specimen_id, training_id, perpage, page },
		})
		return data
	}

	async getFrequentVitaminsByTraining({ training_id, specimen_id }) {
		const { data } = await axios.get(`${base}get-frequent-vitamins-by-training`, {
			params: { training_id, specimen_id },
		})
		return data
	}

	async getTrainerOpts() {
		const { data } = await axios.get(`${base}get-trainers`)
		return data
	}

	async getTripDetail({ specimen_id, origin }) {
		const { data } = await axios.get(`${base}trip/get-detail`, {
			params: { specimen_id, origin },
		})
		return data
	}
}
export default new PreparacionService()
