<template>
	<div class="d-flex align-center container-text-type">
		<div class="icon">
			<template v-if="item.type_id == 4">
				<img src="@/assets/images/icons-line/line4.svg" />
			</template>
			<template v-if="item.type_id == 5">
				<img src="@/assets/images/icons-line/line5.svg" />
			</template>
			<template v-if="item.type_id == 3">
				<img src="@/assets/images/icons-line/line3.svg" />
			</template>
			<template v-if="item.type_id == 1">
				<!-- <Line1/> -->
				<img src="@/assets/images/icons-line/line1.svg" />
			</template>
			<template v-if="item.type_id == 2">
				<img src="@/assets/images/icons-line/line2.svg" />
			</template>
			<template v-if="item.type_id == 6">
				<img src="@/assets/images/icons-line/line6.svg" />
			</template>
		</div>

		<p class="mb-0 text-type">{{ item.action }}</p>
	</div>
</template>

<script>
export default {
	name: "TypeLineItem",
	components: {},
	props: {
		item: {
			type: Object,
			default: () => {},
		},
	},
	data() {
		return {
			bool: false,
		}
	},
}
</script>

<style scoped lang="scss">
.container-text-type {
	position: relative;
	// background: green;
	height: 40px;
	display: flex;
	align-items: center;
	.icon {
		border-radius: 50%;
		border: 2px solid #7367f0;
		height: 40px;
		width: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #fff;
	}
	margin-bottom: 1rem;
	&:last-child {
		margin-bottom: 0;
	}
}
.text-type {
	// background: red;
	// position: absolute;
	// top: 0;
	// left: 30px;
	font-size: 12px;
	width: 300px;
	// transform: translateY(-50%);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 40px;
	color: #323232;
	font-weight: 500;
	margin-left: 0.3rem;
}
</style>
