<template>
	<b-modal
		ref="modal-trip-detail"
		:title="getTitle"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xsmd"
		hide-footer
	>
		<div>
			<b-table-simple responsive sticky-header="50vh" no-border-collapse>
				<b-thead class="">
					<b-th class="text-center">Torneo</b-th>
					<b-th class="text-center">Ciudad</b-th>
					<b-th class="text-center">Fecha</b-th>
					<b-th class="text-center">Dias</b-th>
					<b-th class="text-center">Etapa</b-th>
					<b-th class="text-center" v-if="origin == 'dashboard'">Jugó</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(trip, index) in trips">
						<b-tr :key="index">
							<b-td class="text-center">
								<div style="display: flex; gap: 0.7rem; align-items: center">
									<ViewImage
										:specimen="{
											id: 1,
											url: trip.url_image,
										}"
									/>
									<span class="text-left">
										{{ trip.tournament.name }}
									</span>
								</div>
							</b-td>
							<b-td class="text-center">{{ trip.coliseum.city }}</b-td>
							<b-td class="text-center">{{ trip.date | myGlobalDayShort }}</b-td>
							<b-td class="text-center">
								<span v-if="index === 0">{{ trip.days === 0 ? "---" : trip.days }}</span>
								<span v-else>{{ trip.next_days }}</span>
							</b-td>
							<b-td class="text-center">{{ trip.stage.name }}</b-td>
							<b-td class="text-center" v-if="origin == 'dashboard'">
								<b-badge v-if="trip.is_played === 1" variant="success">SÍ</b-badge>
								<b-badge v-else variant="danger">NO</b-badge>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="trips.length === 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import ViewImage from "@/components/commons/ViewImage.vue"
import preparacionService from "@/services/preparacion/default.service.js"

export default {
	components: { ViewImage },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		origin: String,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			title: null,
			rowInfo: {},
			trips: [],
		}
	},
	async created() {
		await this.getTrips()
	},
	computed: {
		getTitle() {
			return `Trip: ${
				this.info.specimen.alias
					? `${this.info.specimen.alias} (${this.info.specimen.plate})`
					: this.info.specimen.plate
			}`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},

		async getTrips() {
			this.isPreloading()
			try {
				const { data } = await preparacionService.getTripDetail({
					specimen_id: this.info.specimen.id,
					origin: this.origin,
				})
				this.trips = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	async mounted() {
		this.toggleModal("modal-trip-detail")
	},
}
</script>
