<template>
	<div>
		<b-alert show class="p-2" variant="warning" v-if="blockedBySeasons">
			<h5 class="m-0">
				Es necesario
				<router-link :to="{ name: 'preparacion-settings-seasons' }">agregar</router-link>
				una nueva temporada
			</h5>
		</b-alert>
		<b-card class="mr-1 p-1" no-body>
			<b-row>
				<b-col class="d-flex align-items-center flex-column justify-content-center">
					<!-- <pre>{{ counters }}</pre> -->
					<b-nav pills class="m-0 d-flex flex-column container-tabs" style="gap: 1rem">
						<b-nav-item
							@click="changeTab(1)"
							class="position-relative"
							active-class="active"
							:active="curRoute == 1"
						>
							<span :class="{ 'mr-2': counters.actives }">En proceso</span>
							<b-badge
								class="position-absolute"
								style="right: 1rem"
								variant="danger"
								v-if="counters.actives"
							>
								{{ counters.actives }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							@click="changeTab(2)"
							class="position-relative"
							active-class="active"
							:active="curRoute == 2"
						>
							<span :class="{ 'mr-2': counters.finished }">Pre-listos</span>
							<b-badge
								class="position-absolute"
								style="right: 1rem"
								variant="danger"
								v-if="counters.finished"
							>
								{{ counters.finished }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							@click="changeTab(6)"
							class="position-relative"
							active-class="active"
							:active="curRoute == 6"
						>
							<span :class="{ 'mr-2': counters.trip }">Trip</span>
							<b-badge
								class="position-absolute"
								style="right: 1rem"
								variant="danger"
								v-if="counters.trip"
							>
								{{ counters.trip }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							@click="changeTab(4)"
							class="position-relative"
							active-class="active"
							:active="curRoute == 4"
						>
							<span :class="{ 'mr-2': counters.played }">Jugados</span>
							<b-badge
								class="position-absolute"
								style="right: 1rem"
								variant="danger"
								v-if="counters.played"
							>
								{{ counters.played }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							@click="changeTab(5)"
							class="position-relative"
							active-class="active"
							:active="curRoute == 5"
						>
							<span :class="{ 'mr-2': counters.inactives }">Inactivos</span>
							<b-badge
								class="position-absolute"
								style="right: 1rem"
								variant="danger"
								v-if="counters.inactives"
							>
								{{ counters.inactives }}
							</b-badge>
						</b-nav-item>
						<b-nav-item
							@click="changeTab(3)"
							class="position-relative"
							active-class="active"
							:active="curRoute == 3"
						>
							<span :class="{ 'mr-2': counters.canceled }">Cancelados</span>
							<b-badge
								class="position-absolute"
								style="right: 1rem"
								variant="danger"
								v-if="counters.canceled"
							>
								{{ counters.canceled }}
							</b-badge>
						</b-nav-item>
					</b-nav>
				</b-col>

				<b-col class="d-flex flex-column flex-xl-row justify-content-end align-items-center" style="gap: 1rem">
					<b-button variant="primary" @click="showRecomendations = true">Recomendaciones</b-button>

					<b-dropdown text="Agregar" class="m-md-2" variant="success">
						<b-dropdown-item
							@click="showAddTopes = !showAddTopes"
							:disabled="blockedBySeasons || isInactiveSeason"
							v-if="!dashboard && !isInvitado"
						>
							Topes

							<!-- <feather-icon icon="PlusIcon" /> -->
						</b-dropdown-item>
						<b-dropdown-item
							@click="showAddPreparation = !showAddPreparation"
							:disabled="blockedBySeasons || isInactiveSeason"
							v-if="!dashboard && !isInvitado"
						>
							Entrenamiento

							<!-- <feather-icon icon="PlusIcon" /> -->
						</b-dropdown-item>
						<b-dropdown-item
							@click="showAddSpecimen = !showAddSpecimen"
							:disabled="blockedBySeasons || isInactiveSeason"
							v-if="!dashboard && !isInvitado"
						>
							Ejemplar

							<!-- <feather-icon icon="PlusIcon" /> -->
						</b-dropdown-item>
					</b-dropdown>
					<b-form-select :options="seasonOpts" v-model="season" style="max-width: 10rem" />
					<b-input-group style="max-width: 20rem" v-if="!dashboard">
						<b-form-input
							placeholder="Ingrese placa o alias"
							v-model="search"
							@keyup.enter="gridTrigger++"
							style="min-width: 10rem"
						/>
						<b-input-group-append>
							<b-button size="sm" variant="outline-primary" @click="gridTrigger++">
								<feather-icon icon="SearchIcon" />
							</b-button>
							<b-button size="sm" variant="outline-primary" @click="showFilters = !showFilters">
								<feather-icon icon="FilterIcon" />
							</b-button>
						</b-input-group-append>
					</b-input-group>
				</b-col>
			</b-row>
		</b-card>
		<b-card class="p-1" no-body>
			<Grid
				:trigger="gridTrigger"
				:status="curRoute"
				:search="search"
				:filters="filters"
				:season="curSeason"
				:blocked="blockedBySeasons || blockedByTab || dashboard || isInvitado"
				@refresh="getCounters"
			/>
		</b-card>
		<AddSpecimen
			:show="showAddSpecimen"
			:info="curSeason"
			@closing="showAddSpecimen = false"
			@reload="gridTrigger++"
		/>
		<AddPreparation
			:show="showAddPreparation"
			:info="curSeason"
			@closing="showAddPreparation = false"
			@reload="gridTrigger++"
		/>
		<AddTopes :show="showAddTopes" :info="curSeason" @closing="showAddTopes = false" @reload="gridTrigger++" />
		<RecomendationsModal v-if="showRecomendations" @closing="showRecomendations = false" />
		<b-sidebar backdrop width="25vw" title="Busqueda avanzada" v-model="showFilters">
			<div class="p-1" style="overflow: hidden">
				<b-form-group class="pt-3">
					<template #label>
						<div class="d-flex align-items-center">
							Tope
							<small class="ml-1">(Promedio)</small>
							<b-form-checkbox class="ml-1" v-model="filters.activeAverages" switch inline />
						</div>
					</template>
					<veeno
						class="px-2"
						connect
						:disabled="!filters.activeAverages"
						:tooltips="[true, true]"
						:handles="filters.averages"
						:range="{ min: 0.5, max: 5 }"
						:step="0.5"
						@change="sliderChangeAverages"
						:key="filters.averages[0]"
					/>
				</b-form-group>
				<b-form-group class="pt-3">
					<template #label>
						<div class="d-flex align-items-center">
							Entrenamiento
							<small class="ml-1">(Promedio)</small>
							<b-form-checkbox class="ml-1" v-model="filters.activeTrainings" switch inline />
						</div>
					</template>
					<veeno
						class="px-2"
						connect
						:disabled="!filters.activeTrainings"
						:tooltips="[true, true]"
						:handles="filters.trainings"
						:range="{ min: 0.5, max: 5 }"
						:step="0.5"
						@change="sliderChangeTrainings"
						:key="filters.trainings[0]"
					/>
				</b-form-group>
				<!--  -->
				<b-form-group label="Filtrar por fecha" v-if="curRoute != 1">
					<b-form-radio-group
						v-model="filters.dateType"
						:options="[
							{ value: 1, text: 'Inicio' },
							{ value: 2, text: 'Fin' },
						]"
					></b-form-radio-group>
				</b-form-group>
				<template v-if="(curRoute == 1) | (filters.dateType != null)">
					<b-form-group label="Desde">
						<flat-pickr
							class="form-control bg-white"
							v-model="filters.dateStart"
							placeholder="Seleccionar fecha"
						/>
					</b-form-group>
					<b-form-group label="Hasta">
						<flat-pickr
							class="form-control bg-white"
							v-model="filters.dateEnd"
							placeholder="Seleccionar fecha"
						/>
					</b-form-group>
				</template>
			</div>
			<template #footer>
				<div class="d-flex justify-content-end p-1" style="gap: 1rem">
					<b-button @click="clearFilter">Limpiar</b-button>
					<b-button variant="success" @click=";(showFilters = false), gridTrigger++">Buscar</b-button>
				</div>
			</template>
		</b-sidebar>
	</div>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import preparacionSettingService from "@/services/preparacion/settings.service.js"
import AddPreparation from "./components/AddPreparation.vue"
import RecomendationsModal from "@/views/amg/preparacion/components/recommendedSpecimens/RecomendationsModal.vue"
import AddTopes from "./components/AddTopes.vue"
import Grid from "./components/Grid.vue"
import AddSpecimen from "./components/AddSpecimen.vue"
import veeno from "veeno"
import moment from "moment"
import "nouislider/distribute/nouislider.min.css"

export default {
	components: { Grid, AddSpecimen, veeno, AddPreparation, AddTopes, RecomendationsModal },
	props: {
		dashboard: Boolean,
	},
	data: () => ({
		childAction: "",
		search: null,
		season: null,
		seasonOpts: [],
		blockedBySeasons: false,
		showAddSpecimen: false,
		showAddPreparation: false,
		showAddTopes: false,
		showRecomendations: false,
		gridTrigger: 0,
		counters: {
			actives: 0,
			finished: 0,
			trip: 0,
			canceled: 0,
			played: 0,
			inactives: 0,
		},
		//
		showFilters: false,
		qualityOpts: [],
		defectOpts: [],
		quality: null,
		defect: null,
		filters: {
			qualities: null,
			defects: null,
			averages: [0, 5],
			activeAverages: false,
			trainings: [0, 5],
			activeTrainings: false,
			specimen_id: null,
			dateType: null,
			dateStart: null,
			dateEnd: null,
		},
		//
		curTab: null,
	}),
	computed: {
		curSeason() {
			if (!this.season) {
				let tomorrow = new Date()
				tomorrow.setDate(tomorrow.getDate() + 1)
				tomorrow = moment(tomorrow).format("YYYY-MM-DD")
				let lastDayOfYear = moment(tomorrow).endOf("year").format("YYYY-MM-DD")
				return { value: null, text: null, start: tomorrow, end: lastDayOfYear }
			}
			return this.seasonOpts.find((season) => season.value == this.season)
		},
		curRoute() {
			if (this.dashboard) {
				if (!this.curTab) {
					this.curTab = 1
					return this.curTab
				}
				return this.curTab
			}
			switch (this.$route.name) {
				case "preparacion-sr-actives":
					return 1
				case "preparacion-sr-finished":
					return 2
				case "preparacion-sr-canceled":
					return 3
				case "preparacion-sr-played":
					return 4
				case "preparacion-sr-inactive":
					return 5
				case "preparacion-sr-trip":
					return 6
				default:
					return -1
			}
		},
		blockedByTab() {
			return [2, 3, 4, 5, 6].includes(this.curRoute)
		},
		specimenIdCurrent() {
			return this.$router.history.current.params ? this.$router.history.current.params.specimen_id : null
		},
		isInactiveSeason() {
			let cur = this.seasonOpts.find((opt) => opt.text == this.season)
			if (!cur) return null
			return cur.active == 0
		},
	},
	methods: {
		async getSeasons() {
			const { data } = await preparacionSettingService.getSeasons()
			this.seasonOpts = data.map((season) => ({
				id: season.id,
				value: season.season,
				text: season.season,
				start: season.start,
				end: season.end,
				active: season.active_season,
			}))
			const noActiveSeason = !data.some((season) => season.active_season != 0)
			if (noActiveSeason) {
				this.blockedBySeasons = true
				if (data[0]) {
					this.season = data[0].season
				} else {
					this.season = null
				}
				return
			}
			this.season = data.find((season) => season.active_season == 1).season
			this.getCounters()
		},
		async getCounters() {
			const { data } = await preparacionService.getCounterTrainings({
				start_date: this.curSeason.start,
				end_date: this.curSeason.end,
				specimen_id: this.filters.specimen_id || this.specimenIdCurrent,
			})
			// console.log(data)
			this.counters.actives = data.find((opt) => opt.id == 1).counter
			this.counters.finished = data.find((opt) => opt.id == 2).counter
			this.counters.trip = data.find((opt) => opt.id == 6).counter
			this.counters.canceled = data.find((opt) => opt.id == 3).counter
			this.counters.played = data.find((opt) => opt.id == 4).counter
			this.counters.inactives = data.find((opt) => opt.id == 5).counter
		},
		async getOpts() {
			const responses = [
				preparacionSettingService.getQualities({ status: 1 }),
				preparacionSettingService.getDefects({ status: 1 }),
			]
			const data = await Promise.all(responses)
			this.qualityOpts = data[0].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.defectOpts = data[1].data.map((opt) => ({ value: opt.id, text: opt.name }))
		},
		sliderChangeAverages(e) {
			this.filters.averages = [Number(e.values[0]), Number(e.values[1])]
		},
		sliderChangeTrainings(e) {
			this.filters.trainings = [Number(e.values[0]), Number(e.values[1])]
		},
		clearFilter() {
			this.filters = {
				qualities: null,
				defects: null,
				averages: [1, 10],
				activeAverages: false,
				trainings: [1, 10],
				activeTrainings: false,
				specimen_id: null,
				dateType: null,
				dateStart: null,
				dateEnd: null,
			}
			this.showFilters = false
			this.gridTrigger++
		},
		changeTab(to) {
			if (this.dashboard) {
				this.curTab = to
				this.gridTrigger++
				return
			}
			switch (to) {
				case 1:
					this.$router.push({ name: "preparacion-sr-actives" })
					break
				case 2:
					this.$router.push({ name: "preparacion-sr-finished" })
					break
				case 3:
					this.$router.push({ name: "preparacion-sr-canceled" })
					break
				case 4:
					this.$router.push({ name: "preparacion-sr-played" })
					break
				case 5:
					this.$router.push({ name: "preparacion-sr-inactive" })
					break
				case 6:
					this.$router.push({ name: "preparacion-sr-trip" })
					break
				default:
					return -1
			}
		},
	},
	mounted() {
		if (this.dashboard) {
			this.filters.specimen_id = this.$route.params.specimen_id
			this.gridTrigger++
		}
	},
	watch: {
		curRoute(val) {
			this.clearFilter()
		},
		curSeason(val) {
			this.gridTrigger++
		},
	},
	created() {
		this.getOpts()
		this.getSeasons()
	},
}
</script>

<style lang="scss" scoped>
.container-tabs {
	width: 100% !important;
	margin-bottom: 1rem !important;
	@media screen and (min-width: 800px) {
		flex-direction: row !important;
		align-items: center;
		margin-bottom: 0 !important;

		//    display: grid !important;
		//    grid-template-columns: 1fr 1fr 3fr;
		//    gap: 1rem;
	}
}
.container-add-specimen {
	// background: red !important;;
	width: 100% !important;

	@media screen and (min-width: 1000px) {
		//    flex-direction: row !important;
		align-items: center;

		display: grid !important;
		grid-template-columns: 1fr 1fr 3fr;
		gap: 1rem;
	}
}

.sidebar-preparacion {
	width: 100vw;

	@media screen and (min-width: 1000px) {
		width: 25vw;
	}
}
</style>
