<template>
	<div class="d-flex align-items-center justify-content-center">
		<p v-if="startCheck" class="mb-0">Desparasitacion</p>

		<template v-if="!blocked">
			<b-button class="p-0 ml-1 " :class="[!startCheck ? 'cursor-pointer' : '']" size="sm" variant="dark" :disabled="disabled" @click="save()">
				<feather-icon size="20" icon="PlusIcon" />
			</b-button>
		</template>
	</div>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js";
export default {
	name: "GlobalChangeDeworn",
	props: {
		rows: {
			type: Array,
		},
		blocked: {
			type: Boolean,
		},
		disabled: Boolean
	},
	data() {
		return {
			// vitaminOpts: [],
			// selectVitamin: null,
			startCheck: true,
		};
	},
	async mounted() {},
	computed: {
		checkMark() {
			return this.rows.filter((it) => it._check).length;
		},
	},
	methods: {
		async save() {
			const specimensCheck = this.rows.filter((it) => it._check);
			if (specimensCheck.length == 0) {
				this.showToast("warning", "top-right", "Preparación", "InfoIcon", "Sin ejemplares seleccionados");
				this.selectVitamin = null;
				return;
			}
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se desparasitará a ejemplares (<span class="font-weight-bolder">${specimensCheck.map(
					(it) => it.specimen.plate
				)}</span>)</p>
				`,
			});
			if (!isConfirmed) {
				this.selectVitamin = null;
				return;
			}
			await preparacionService.insertDeworn({
				trainings: specimensCheck.map((it) => it.training_id),
			});
			this.selectVitamin = null;
			this.$emit("refresh");
		},
	},
};
</script>

<style lang="scss" scoped>
.container-select-vitamin {
	width: 120px;
	background: white;
}
</style>
