<template>
	<b-modal centered hide-footer size="lg" v-model="isActive" @hidden="handleHidden">
		<template slot="modal-header">
			<div class="d-flex justify-content-start">
				<h5 class="mb-0">{{ titleModal }}</h5>
				<!-- <p class="mb-0 ml-50 d-flex align-items-center" v-if="info.routine_last && info.routine_last.average">
					- Calificación promedio:
					<span class="badge ml-50" :style="{ backgroundColor: '#7367f0' }">
						{{ Number(info.routine_last.average).toFixed(2) }}
						<feather-icon icon="StarIcon" style="margin: 0 0 0px 4px" />
					</span>
				</p> -->
			</div>
			<button @click="isActive = false" type="button" aria-label="Close" class="close">×</button>
		</template>

		<b-overlay :show="isLoading">
			<div class="container-dates">
				<div class="dates" v-for="row in rows" :key="row.id">
					<span class="mb-0 text-center">{{ row.created_by.date | myGlobalDayShort }}</span>
					<p class="mb-0 text-center">{{ row.created_by.user }}</p>
				</div>
			</div>

			<!-- <b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Ejercicio(s)</b-th>
						<b-th>Duracion</b-th>
						<b-th>Comentario</b-th>
						<b-th>Calificacion</b-th>
						<b-th>Creado por</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>
							<p
								v-for="(ph, index) in row.training_physical"
								:key="index"
								class="mb-0 font-small-3 font-weight-bold text-left"
							>
								{{ index + 1 }}. {{ ph.name }}
							</p>
						</b-td>

						<b-td>{{ row.time }} M</b-td>
						<b-td>{{ row.comment || "---" }}</b-td>
						<b-td>
							<span class="text-primary">{{ Number(row.rating).toFixed(2) }}</span>
						</b-td>
						<b-td>
							<p class="mb-0">{{ row.created_by.user }}</p>
							<p class="mb-0">{{ row.created_by.date | myGlobalDayShort }}</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple> -->
		</b-overlay>
		<b-modal centered size="sm" v-model="modalReview.show" :title="modalReview.title" @hidden="modalHidden">
			<b-overlay :show="modalReview.loading">
				<b-form-group>
					<template #label>Calificacion</template>
					<star-rating :increment="0.5" :star-size="40" v-model="modalReview.rating" />
				</b-form-group>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="success" @click="sendReview">Guardar</b-button>
			</template>
		</b-modal>
	</b-modal>
</template>

<script>
import VideoCloudflare from "../../tournaments/VideoCloudflare.vue"
import preparacionService from "@/services/preparacion/default.service.js"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	components: { SpecimenPlate, VideoCloudflare },
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		//
		modalReview: {
			show: false,
			loading: false,
			info: {},
			rating: 0,
			title: "Registrar calificacion",
		},
		averageRating: 0,
	}),
	computed: {
		titleModal() {
			let txt = ""
			if (this.info.specimen) {
				txt += `Entrenamientos / ${this.info.specimen.alias ? this.info.specimen.alias : ""} (${
					this.info.specimen.plate
				})`
			}
			return txt
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.modalHidden()
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			const { data } = await preparacionService.getTrackingRoutines({ training_id: this.info.training_id })

			this.averageRating =
				data
					.map((it) => parseFloat(it.rating))
					.reduce((accumulator, currentValue) => accumulator + currentValue, 0) / data.length
			this.rows = data
			this.isLoading = false
		},
		async sendReview() {
			this.modalReview.loading = true
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se registrara puntaje para entrenamiento del especimen ${
					this.info.specimen.alias || this.info.specimen.plate
				}`,
			})
			if (!isConfirmed) {
				this.modalHidden()
				return
			}
			await preparacionService.updateTraining({
				training_routine_id: this.modalReview.info.id,
				rating: this.modalReview.rating,
			})
			this.modalReview.loading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		colorizePoints(points) {
			if (points == 2.5) return "bg-info-light"
			else if (points == null) return "bg-secondary-light"
			else if (points > 2.5) return "bg-success-light"
			else if (points < 2.5) return "bg-danger-light"
		},
		modalHidden() {
			this.modalReview = {
				show: false,
				loading: false,
				info: {},
				rating: 0,
				title: "Registrar calificacion",
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows()
				this.isActive = val
			}
		},
	},
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.bg-info-light {
	background: lighten($color: $blue, $amount: 25%);
	color: $dark;
}

.bg-success-light {
	background: lighten($color: $green, $amount: 25%);
	color: $dark;
}

.bg-danger-light {
	background: lighten($color: $red, $amount: 25%);
	color: $dark;
}

.container-dates {
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: 1rem;
	.dates {
		p {
			font-size: 15px;
			margin-bottom: 0.1rem;
		}
		display: flex;
		flex-direction: column;
		span {
			font-weight: bolder !important;
		}
		border: 1px solid #ebebeb;
		border-left: 4px solid #7367f0;
		box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	}
}
</style>
