<template>
	<div>
		<b-overlay :show="isLoading">
			<b-row class="mb-1">
				<b-col class="d-flex align-items-center">
					<small class="text-secondary">
						Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
					</small>
				</b-col>
				<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
					<b-button
						v-if="status == 1"
						variant="primary"
						:class="{ 'btn-sm': !massiveDisabled }"
						:disabled="massiveDisabled"
						@click="openModalWeightMassive"
					>
						Enviar a Pre-listos
						<b-badge v-if="!massiveDisabled" variant="danger">{{ getCountSelected }}</b-badge>
					</b-button>
					<v-select
						style="width: 20rem"
						placeholder="Filtrar preparador"
						label="label"
						v-model="trainer"
						:options="trainerOpts"
						:reduce="(opt) => opt.id"
						@input="getRows"
					/>
					<b-pagination
						v-model="pagination.curPage"
						:total-rows="pagination.total"
						:per-page="pagination.perPage"
						first-number
						last-number
						class="mb-0 mt-1 mt-sm-0"
						prev-class="prev-item"
						next-class="next-item"
					>
						<template #prev-text>
							<feather-icon icon="ChevronLeftIcon" size="18" />
						</template>
						<template #next-text>
							<feather-icon icon="ChevronRightIcon" size="18" />
						</template>
					</b-pagination>
				</b-col>
			</b-row>
			<b-table-simple sticky-header="60vh">
				<b-thead class="text-center text-nowrap">
					<b-tr>
						<b-th v-if="!isInvitado && !blocked">
							<b-form-checkbox v-model="massiveCheck" @change="toogleAllCheck" />
						</b-th>
						<b-th>Ejemplar</b-th>
						<!-- <b-th v-if="[6].includes(status)">Viajes</b-th> -->
						<b-th>Viajes</b-th>
						<b-th>Entrenamiento</b-th>
						<b-th>
							<div class="d-flex align-items-center justify-content-center">
								Topes
								<p class="mb-0 badge badge-danger ml-25" v-if="status == 1 && pendingsTotal > 0">
									{{ pendingsTotal }}
								</p>
							</div>
						</b-th>
						<!-- <b-th>Promedio Total</b-th> -->
						<b-th>Dias</b-th>

						<b-th>
							Suplemento
							<b-button
								class="p-0 ml-1"
								:class="[blocked ? 'cursor-pointer' : '']"
								size="sm"
								variant="dark"
								v-if="!blocked"
								@click="sendRows(null, 'vitamin')"
								:disabled="massiveDisabled"
							>
								<feather-icon size="20" icon="PlusIcon" />
							</b-button>
						</b-th>
						<b-th>
							Kg / Lb
							<b-button
								class="p-0 ml-1"
								:class="[blocked ? 'cursor-pointer' : '']"
								size="sm"
								variant="dark"
								v-if="!blocked"
								@click="sendRows(null, 'weight')"
								:disabled="massiveDisabled"
							>
								<feather-icon size="20" icon="PlusIcon" />
							</b-button>
						</b-th>
						<b-th>
							<GlobalChangeDeworn
								:blocked="blocked"
								:disabled="massiveDisabled"
								:rows="rows"
								@refresh="getRows"
							/>
						</b-th>
						<!-- <b-th v-if="![3, 4, 5].includes(status)">Enfermeria</b-th> -->
						<b-th v-if="!isInvitado">Acciones</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr :variant="rowInDanger(row)" v-for="row in rows" :key="row.training_id">
						<b-td v-if="!isInvitado && !blocked">
							<b-form-checkbox v-model="row._check" />
						</b-td>
						<b-td class="position-relative">
							<SpecimenPlate :specimen="row.specimen" />
							<small class="d-block mt-05 text-left">
								Edad:
								<b>{{ row.specimen.dob | untilYearMonth }}</b>
							</small>
							<small class="d-block mt-05 text-left" v-if="[4, 5].includes(status)">
								<b-badge :variant="colorizeSpecimenStatus(row.specimen.status_id)">
									Estado:
									<b>{{ row.specimen.status }}</b>
								</b-badge>
							</small>
							<small class="d-block mt-05 text-left">
								Preparador:
								<b>{{ row.created.user }}</b>
							</small>
							<div
								style="
									position: absolute;
									top: 10px;
									right: 0;
									display: flex;
									flex-direction: column;
									gap: 0.5rem;
								"
							>
								<div v-if="[1, 2, 5].includes(status) && row.specimen.status_id != 3" class="">
									<b-button
										class="btn-icon"
										size="sm"
										variant="flat-primary"
										@click="
											;(addNursery.info = { specimen: row.specimen }),
												(addNursery.show = !addNursery.show)
										"
										v-if="!isInvitado && row.nursing_status_id === null"
										v-b-tooltip.hover
										title="ENVIAR A ENFERMERIA"
									>
										<v-icon name="md-healthandsafety-outlined" scale="1" class="text-danger" />
									</b-button>

									<template v-else>
										<div
											v-b-tooltip.hover
											:title="
												row.nursing_status_id == 1
													? 'Ejemplar en espera'
													: 'Ejemplar en proceso'
											"
										>
											<v-icon
												name="md-healthandsafety-outlined"
												scale="1"
												:class="[row.nursing_status_id == 1 ? 'text-info' : 'text-success']"
											/>
										</div>
									</template>
								</div>

								<div class="position-relative">
									<feather-icon
										@click="openIncidenceDetail(row)"
										v-b-tooltip.hover="'Ver incidencias'"
										class="text-primary"
										:class="{ 'icon-disabled': row.incidence_counter === 0 }"
										icon="AlertOctagonIcon"
										size="19"
										style="cursor: pointer"
									/>
									<span v-if="row.incidence_counter > 0" class="incidences-counter">
										{{ row.incidence_counter }}
									</span>
								</div>
							</div>
						</b-td>
						<!-- <b-td v-if="[6].includes(status)">
							<span v-if="!row.counter_trip || row.counter_trip === 0">0</span>
							<div class="cursor-pointer" v-else @click="openTripDetail(row)">
								<b-avatar variant="light-primary" rounded="sm">
									{{ row.counter_trip }}
								</b-avatar>
							</div>
						</b-td> -->
						<b-td>
							<span v-if="!row.counter_trip || row.counter_trip === 0">0</span>
							<div class="cursor-pointer" v-else @click="openTripDetail(row)">
								<b-avatar variant="light-primary" rounded="sm">
									{{ row.counter_trip }}
								</b-avatar>
							</div>
						</b-td>
						<b-td>
							<b-button
								class="btn-icon rounded-circle clickable ml-05"
								variant="outline-primary"
								size="sm"
								@click="
									;(modalTrackingTraining.show = !modalTrackingTraining.show),
										(modalTrackingTraining.info = row)
								"
								v-if="row.routine_last"
								style="height: 40px; width: 40px"
							>
								{{ row.routine_last.total }}
							</b-button>
							<span v-else>---</span>
						</b-td>
						<b-td>
							<div class="d-flex flex-column">
								<div class="d-flex flex-column">
									<span class="font-weight-bold text-success">Prom. Topes</span>
									<div class="d-flex justify-content-center align-items-center">
										<span v-if="row.tope_last" class="font-weight-bold text-success">
											{{ Number(row.tope_last.average).toFixed(2) }}
										</span>
										<span v-else class="font-weight-bold text-success">0.00</span>
										<feather-icon
											class="text-success"
											style="margin-left: 5px"
											size="16"
											icon="StarIcon"
										/>
										<b-badge
											v-if="row.tope_last"
											variant="success"
											class="ml-2 cursor-pointer"
											@click="
												;(modalTrackingTope.show = !modalTrackingTope.show),
													(modalTrackingTope.info = { ...row, _status: status })
											"
										>
											{{ row.tope_last.total }}
										</b-badge>
										<b-badge v-else variant="danger" class="ml-2">0</b-badge>
									</div>
								</div>
								<th class="p-0 text-primary" style="margin-top: 5px; margin-bottom: 5px" />
								<div class="">
									<span class="font-weight-bold text-primary">Prom. General</span>
									<div class="d-flex justify-content-center align-items-center">
										<span class="font-weight-bold text-primary">
											{{ Number(row.total_average).toFixed(2) }}
										</span>
										<feather-icon
											size="16"
											icon="StarIcon"
											class="text-primary"
											style="margin-left: 5px"
										/>
										<b-badge
											v-if="row.count_total_average > 0"
											variant="primary"
											class="ml-2 cursor-pointer"
											@click="
												;(modalTopesBySpecimenTraking.show = !modalTopesBySpecimenTraking.show),
													(modalTopesBySpecimenTraking.info = row)
											"
										>
											{{ row.count_total_average }}
										</b-badge>
										<b-badge v-else variant="danger" class="ml-2">0</b-badge>
									</div>
								</div>
							</div>
							<!-- <div class="d-flex justify-content-center align-items-center" style="gap: 1rem">
								<div v-if="row.tope_last" class="position-relative">
									<div class="d-flex flex-column align-items-end mt-1" style="width: 100%">
										<div class="text-primary d-flex align-items-center mb-50" style="">
											<p class="mb-0 mr-25 font-weight-bold">
												{{ Number(row.tope_last.average).toFixed(2) }}
											</p>
											<feather-icon class="cursor-pointer" size="16" icon="StarIcon" />
										</div>
									</div>
									<b-button
										class="btn-icon rounded-circle clickable ml-05"
										variant="outline-primary"
										size="sm"
										@click="
											;(modalTrackingTope.show = !modalTrackingTope.show),
												(modalTrackingTope.info = { ...row, _status: status })
										"
										style="height: 32px; width: 32px"
									>
										{{ row.tope_last.total }}
									</b-button>
								</div>
								<span class="text-nowrap" v-else>---</span>
							</div> -->
						</b-td>
						<!-- <b-td>
							<div
								class="d-flex justify-content-center gap-x-1 text-primary font-weight-bold"
								v-if="row.total_average"
							>
								{{ Number(row.total_average).toFixed(2) }}
								<feather-icon size="16" icon="StarIcon" />
							</div>
							<span v-else>---</span>
						</b-td> -->
						<b-td>
							<template v-if="status == 1">
								<span class="text-nowrap">{{ row.created.date | daysSince }}</span>
								<br />
								<small v-b-tooltip:hover.bottom="`Inicio`">
									{{ row.created.date | myGlobalDayShort }}
								</small>
							</template>
							<template v-else>
								<span class="text-nowrap">
									{{ dateDiffFormat(row.created.date, row.updated.date) }}
								</span>
								<br />
								<small class="text-nowrap" v-b-tooltip:hover.bottom="`INICIO - FIN`">
									{{ row.created.date | myGlobalDayShort }} -
									{{ row.updated.date | myGlobalDayShort }}
								</small>
							</template>
						</b-td>

						<b-td>
							<div class="d-flex justify-content-center align-items-center" style="gap: 1rem">
								<div v-if="row.vitamin_last" class="d-flex justify-content-center align-items-center">
									<div class="d-flex">
										<b-button
											class="btn-icon rounded-circle clickable ml-1 p-0"
											variant="outline-primary"
											size="sm"
											v-if="!blocked"
											@click="sendRows(row, 'vitamin')"
										>
											<feather-icon size="18" icon="PlusIcon" />
										</b-button>
									</div>
									<b-button
										class="btn-icon rounded-circle clickable ml-05"
										variant="outline-primary font-medium-3"
										size="sm"
										v-if="row.vitamin_last"
										@click="
											;(modalTrackingVitamins.show = !modalTrackingVitamins.show),
												(modalTrackingVitamins.info = row)
										"
									>
										{{ row.vitamin_last.total }}
									</b-button>
								</div>
								<span v-else>---</span>
								<b-button
									class="btn-icon"
									style="padding: 2px"
									variant="outline-primary"
									size="sm"
									v-if="!blocked && !row.vitamin_last"
									@click="sendRows(row, 'vitamin')"
								>
									<feather-icon class="cursor-pointer" size="16" icon="PlusIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center align-items-center flex-column" style="gap: 1rem">
								<div v-if="row.weight_last" class="d-flex flex-column align-items-center">
									<p class="">
										<span class="font-weight-bolder">{{ row.weight_last.last_kg_weight }}</span>
										Kg
										<br />
										<span class="font-weight-bolder">{{ row.weight_last.last_pounds_weight }}</span>
										Lb
									</p>
									<div class="d-flex">
										<b-button
											class="btn-icon rounded-circle clickable ml-05"
											variant="primary"
											size="sm"
											v-if="row.weight_last"
											@click="
												;(modalTrackingWeight.show = !modalTrackingWeight.show),
													(modalTrackingWeight.info = { ...row, ...season })
											"
										>
											<feather-icon class="cursor-pointer" size="12" icon="CheckIcon" />
										</b-button>
									</div>
								</div>
								<span :class="[daysToAssign(row.created.date) ? 'text-primary' : 'text-danger']" v-else>
									<span v-if="status == 1">{{ daysToAssignStr(row.created.date) }}</span>
									<span v-else>---</span>
								</span>
								<b-button
									class="btn-icon"
									style="padding: 2px"
									variant="outline-primary"
									size="sm"
									v-if="!blocked && !row.weight_last"
									@click="sendRows(row, 'weight')"
								>
									<feather-icon class="cursor-pointer" size="12" icon="PlusIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center align-items-center flex-column" style="gap: 1rem">
								<div v-if="row.deworn_last">
									<div class="mt-1 d-flex justify-content-center">
										<b-button
											class="btn-icon rounded-circle clickable ml-50 p-0"
											variant="outline-primary"
											size="sm"
											v-if="!blocked && !row.deworn_last"
											@click="insertDeworn(row)"
										>
											<feather-icon size="18" icon="PlusIcon" />
										</b-button>
										<b-button
											class="btn-icon rounded-circle clickable ml-05"
											variant="primary"
											size="sm"
											v-if="row.deworn_last"
											@click="
												;(modalTrackingDeworn.show = !modalTrackingDeworn.show),
													(modalTrackingDeworn.info = row)
											"
										>
											<feather-icon class="cursor-pointer" size="12" icon="CheckIcon" />
										</b-button>
									</div>
								</div>
								<span :class="[daysToAssign(row.created.date) ? 'text-primary' : 'text-danger']" v-else>
									<span v-if="status == 1">{{ daysToAssignStr(row.created.date) }}</span>
									<span v-else>---</span>
								</span>
								<b-button
									class="btn-icon"
									style="padding: 2px"
									variant="outline-success"
									size="sm"
									v-if="!blocked && !row.deworn_last"
									@click="insertDeworn(row)"
								>
									<feather-icon class="cursor-pointer" size="16" icon="PlusIcon" />
								</b-button>
							</div>
						</b-td>
						<b-td v-if="!isInvitado">
							<div class="d-flex justify-content-center" style="gap: 0.5rem">
								<b-button-group>
									<template v-if="[1].includes(status) && !blocked">
										<b-button
											variant="flat-info"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a pre-listo`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'finish',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="CheckIcon" />
										</b-button>
										<b-button
											variant="flat-danger"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a cancelado`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'cancel',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="XIcon" />
										</b-button>
									</template>
									<template v-if="[6].includes(status)">
										<b-button
											variant="flat-info"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a pre-listo`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'finish',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="CheckIcon" />
										</b-button>
										<b-button
											variant="flat-danger"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a cancelado`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'cancel',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="XIcon" />
										</b-button>
									</template>
									<template v-if="[2, 6].includes(status)">
										<b-button
											variant="flat-warning"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a En Proceso`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'return',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="SkipBackIcon" />
										</b-button>
										<b-button
											variant="flat-danger"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a Inactivos`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'inactive',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="SkipForwardIcon" />
										</b-button>
									</template>
									<template v-if="status == 5">
										<b-button
											:disabled="row.specimen.status_id == 3"
											variant="flat-warning"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a En Proceso`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'return',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="SkipBackIcon" />
										</b-button>
										<b-button
											:disabled="row.specimen.status_id == 3"
											variant="flat-danger"
											class="btn-icon"
											v-b-tooltip.hover.top="`Pasar a cancelado`"
											@click="
												;(showActionsModal = !showActionsModal),
													(curSpecimen = {
														...row,
														_a: 'cancel',
														_status: status,
														_season: season,
													})
											"
										>
											<feather-icon size="20" icon="XIcon" />
										</b-button>
									</template>
									<b-button
										variant="flat-secondary"
										class="btn-icon"
										v-b-tooltip.hover.top="`Ver tracking`"
										@click="
											;(showActionsModal = !showActionsModal),
												(curSpecimen = {
													...row,
													_a: 'track',
													_status: status,
													_season: season,
												})
										"
										v-if="status != 1"
									>
										<feather-icon size="20" icon="ListIcon" />
									</b-button>

									<b-button
										variant="flat-success"
										class="btn-icon"
										v-b-tooltip.hover.top="`Ver linea de tiempo`"
										@click="setLineSpecimen(row)"
										:disabled="row.counter_timeline == 0"
									>
										<feather-icon size="20" icon="ActivityIcon" />
									</b-button>
								</b-button-group>
							</div>
						</b-td>
					</b-tr>
					<b-tr v-if="rows.length == 0">
						<b-th colspan="10">No hay registros para mostrar</b-th>
					</b-tr>
				</b-tbody>
			</b-table-simple>

			<div class="ml-2 mt-1 mb-1">
				<h5 class="mb-0">Enfermería</h5>

				<div class="d-flex align-items-center justify-content-start mt-50">
					<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-danger" />
					<small class="mb-0 ml-25">Enviar ejemplar a enfermeria</small>
				</div>

				<div class="d-flex align-items-center justify-content-start mt-50">
					<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-info" />
					<small class="mb-0 ml-25">Ejemplar en espera</small>
				</div>

				<div class="d-flex align-items-center justify-content-start mt-50">
					<v-icon name="md-healthandsafety-outlined" scale="1" class="p-0 text-success" />
					<small class="mb-0 ml-25">Ejemplar en proceso</small>
				</div>
			</div>
		</b-overlay>
		<WeightModal
			:show="showWeightModal"
			:info="selectedSpecimens"
			@closing="showWeightModal = false"
			@refresh="getRows"
			@cleanCheck="cleanSpecimenCheck"
		/>
		<VitaminsModal
			:show="showVitaminsModal"
			:info="selectedSpecimens"
			@closing="showVitaminsModal = false"
			@refresh="getRows"
			@cleanCheck="cleanSpecimenCheck"
		/>
		<TrainingModal
			:show="showTrainingModal"
			:info="curSpecimen"
			@closing="showTrainingModal = false"
			@refresh="getRows"
		/>
		<TopeModal :show="showTopeModal" :info="curSpecimen" @closing="showTopeModal = false" @refresh="getRows" />
		<ActionsModal
			:show="showActionsModal"
			:info="curSpecimen"
			@closing="showActionsModal = false"
			@refresh="getRows(), (blockTrigger = blockTrigger + 1)"
		/>
		<TrackingWeight
			:show="modalTrackingWeight.show"
			:info="modalTrackingWeight.info"
			@closing="modalTrackingWeight.show = false"
		/>
		<TrackingVitamin
			:show="modalTrackingVitamins.show"
			:info="modalTrackingVitamins.info"
			@closing="modalTrackingVitamins.show = false"
		/>
		<TrackingDeworn
			:show="modalTrackingDeworn.show"
			:info="modalTrackingDeworn.info"
			@closing="modalTrackingDeworn.show = false"
		/>
		<TrackingTraining
			:show="modalTrackingTraining.show"
			:info="modalTrackingTraining.info"
			@closing="modalTrackingTraining.show = false"
			@refresh="getRows"
		/>
		<TrackingTope
			:show="modalTrackingTope.show"
			:info="modalTrackingTope.info"
			@closing="modalTrackingTope.show = false"
			@refresh="getRows"
		/>
		<CharacteristicTracking
			:show="modalTrackingCharacteristic.show"
			:info="modalTrackingCharacteristic.info"
			@closing="modalTrackingCharacteristic.show = false"
			@refresh="getRows"
		/>
		<NurseryTracking
			:show="nurseryTracking.show"
			:info="nurseryTracking.info"
			@closing="nurseryTracking.show = false"
		/>
		<AddSpecimensNursery
			:show="addNursery.show"
			:info="addNursery.info"
			@closing="addNursery.show = false"
			@refresh="getRows"
		/>

		<LineLife
			:show="showLineSpecimen.show"
			@closing="showLineSpecimen.show = false"
			:specimen="{
				id: showLineSpecimen.specimen ? showLineSpecimen.specimen.id : null,
				image: showLineSpecimen.specimen ? showLineSpecimen.specimen.image : null,
				plate: showLineSpecimen.specimen ? showLineSpecimen.specimen.plate : null,
				alias: showLineSpecimen.specimen ? showLineSpecimen.specimen.alias : null,
			}"
			:training_id="showLineSpecimen.training_id"
		/>

		<TopesBySpecimenTracking
			:show="modalTopesBySpecimenTraking.show"
			:info="modalTopesBySpecimenTraking.info"
			@closing="modalTopesBySpecimenTraking.show = false"
		/>

		<TripDetailModal
			v-if="showTripModal"
			:show="showTripModal"
			:info="rowInfo"
			:origin="'training'"
			@closing="showTripModal = false"
		/>

		<MassiveWeightModal
			v-if="showWeight"
			:isTraining="true"
			:selecteds="selectedSpecimens"
			@closing="showWeight = false"
			@refresh="getRows"
			@cleanCheck="cleanSpecimenCheck"
		/>

		<SpecimenIncidencesModal
			v-if="incidenceModal.show"
			:show="incidenceModal.show"
			:info="incidenceModal.info"
			@closing="incidenceModal.show = false"
		/>
	</div>
</template>

<script>
import moment from "moment"
import preparacionService from "@/services/preparacion/default.service.js"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import WeightModal from "../components/WeightModal.vue"
import VitaminsModal from "../components/VitaminsModal.vue"
import TrainingModal from "../components/TrainingModal.vue"
import TopeModal from "../components/TopeModal.vue"
import ActionsModal from "./ActionsModal.vue"
import TrackingDeworn from "../components/DewornTracking.vue"
import TrackingWeight from "../components/WeightTracking.vue"
import TrackingVitamin from "../components/VitaminsTracking.vue"
import TrackingTraining from "../components/TrainingTracking.vue"
import TrackingTope from "../components/TopeTracking.vue"
import GlobalChangeDeworn from "./GlobalChangeDeworn.vue"
import CharacteristicTracking from "./CharacteristicTracking.vue"
import NurseryTracking from "@/views/amg/nursery/components/TrackingModal.vue"
import AddSpecimensNursery from "@/views/amg/nursery/components/AddSpecimensNursery.vue"
import LineLife from "../../specimens/dashboard/modals/LineLife.vue"
import TopesBySpecimenTracking from "@/views/amg/preparacion/components/TopesBySpecimenTracking.vue"
import TripDetailModal from "@/views/amg/preparacion/components/recommendedSpecimens/TripDetailModal.vue"
import MassiveWeightModal from "@/views/amg/preparacion/components/MassiveWeightModal.vue"
import SpecimenIncidencesModal from "@/views/amg/header-bookmarks/incidences/SpecimenIncidencesModal.vue"

export default {
	components: {
		SpecimenPlate,
		TrainingModal,
		VitaminsModal,
		WeightModal,
		TopeModal,
		ActionsModal,
		TrackingDeworn,
		TrackingWeight,
		TrackingVitamin,
		TrackingTraining,
		TrackingTope,
		GlobalChangeDeworn,
		CharacteristicTracking,
		AddSpecimensNursery,
		NurseryTracking,
		LineLife,
		TopesBySpecimenTracking,
		TripDetailModal,
		MassiveWeightModal,
		SpecimenIncidencesModal,
	},
	props: {
		trigger: Number,
		search: String,
		status: Number,
		season: Object, // {value: number,text: string,star: string,end: string,}
		blocked: Boolean,
		filters: Object, // {quality: number[],defect: number[],averages: number[2],specimen_id: number}
	},
	data: () => ({
		isLoading: false,
		rows: [],
		curBlock: null,
		rowInfo: {},
		pagination: {
			total: 0,
			curPage: 1,
			perPage: 50,
			from: 0,
			to: 0,
		},
		counters: { lmv: 0, mjs: 0 },
		showTrainingModal: false,
		showTopeModal: false,
		showVitaminsModal: false,
		showWeightModal: false,
		showActionsModal: false,
		showTripModal: false,
		curSpecimen: null,
		selectedSpecimens: [],
		isCanceling: false,
		//
		modalTrackingDeworn: {
			show: false,
			info: {},
		},
		modalTrackingWeight: {
			show: false,
			info: {},
		},
		modalTrackingVitamins: {
			show: false,
			info: {},
		},
		modalTrackingTraining: {
			show: false,
			info: {},
		},
		modalTrackingTope: {
			show: false,
			info: {},
		},
		modalTrackingCharacteristic: {
			show: false,
			info: {},
		},
		modalTopesBySpecimenTraking: {
			show: false,
			info: {},
		},
		pendingsTotal: 0,
		massiveCheck: false,
		blockTrigger: 0,
		nurseryTracking: { show: false, info: {} },
		addNursery: { show: false, info: {} },
		showLineSpecimen: {
			show: false,
			specimen: null,
		},
		trainerOpts: [],
		trainer: null,
		showWeight: false,
		incidenceModal: {
			show: false,
			info: {},
		},
	}),
	computed: {
		massiveDisabled() {
			return !this.rows.some((row) => row._check)
		},
		getCountSelected() {
			return this.rows.filter((row) => row._check).length
		},
		specimenIdCurrent() {
			return this.$router.history.current.params ? this.$router.history.current.params.specimen_id : null
		},
	},
	methods: {
		openIncidenceDetail(data) {
			this.incidenceModal.info = data.specimen
			this.incidenceModal.show = true
		},
		async getRows() {
			this.massiveCheck = false
			this.isLoading = true
			const { data } = await preparacionService.getTrainings({
				status_id: this.status,
				search: this.search,
				start_date: this.season.start,
				end_date: this.season.end,
				page: this.pagination.curPage,
				perpage: this.pagination.perPage,
				block: null,
				// block: 3,
				defects: this.filters.defects && JSON.stringify(this.filters.defects),
				qualities: this.filters.qualities && JSON.stringify(this.filters.qualities),
				average_min: this.filters.activeAverages ? this.filters.averages[0] : null,
				average_max: this.filters.activeAverages ? this.filters.averages[1] : null,
				specimen_id: this.filters.specimen_id || this.specimenIdCurrent,
				average_training_min: this.filters.activeTrainings ? this.filters.trainings[0] : null,
				average_training_max: this.filters.activeTrainings ? this.filters.trainings[1] : null,
				type_filter_date: this.status != 1 ? this.filters.dateType : null,
				filter_start_date: this.filters.dateStart,
				filter_end_date: this.filters.dateEnd,
				preparador_id: this.trainer,
			})
			this.pagination.total = data.total
			this.pagination.from = data.from
			this.pagination.to = data.to
			this.rows = data.data.map((row) => {
				return {
					...row,
					_check: false,
				}
			})
			this.initQualityPendings()

			this.isLoading = false
			this.$emit("refresh")
		},
		async insertDeworn(row, massive = false) {
			this.isLoading = true
			let html, trainings
			if (massive) {
				trainings = this.rows.map((row) => row.training_id)
				html = `
					<p class="mb-0">Se agregará desparacitacion para <span class="font-weight-bolder">${this.rows.length}</span> ejemplares.</p>
				`
			} else {
				trainings = [row.training_id]
				html = `
					<p class="mb-0">Se agregará desparacitacion para <span class="font-weight-bolder">${
						row.specimen.alias || row.specimen.plate
					}</span>.</p>
				`
			}
			const { isConfirmed } = await this.showConfirmSwal({ html })
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			await preparacionService.insertDeworn({ trainings })
			this.isLoading = false
			this.getRows()
		},
		colorizePoints(points) {
			if (points == 2.5) return "bg-info-light"
			else if (points == null) return "bg-secondary-light"
			else if (points > 2.5) return "bg-success-light"
			else if (points < 2.5) return "bg-danger-light"
		},
		toogleAllCheck(e) {
			this.rows.forEach((row) => {
				row._check = e
			})
		},
		sendRows(row, action) {
			let selecteds
			if (row) {
				selecteds = [row]
			} else {
				selecteds = this.rows.filter((row) => row._check)
			}
			this.selectedSpecimens = selecteds
			if (selecteds.length == 0) {
				this.showToast(
					"danger",
					"top-right",
					"Preparacion",
					"AlertCircleIcon",
					`No hay ejemplares seleccionados`
				)
				return
			}
			switch (action) {
				case "weight":
					this.showWeightModal = !this.showWeightModal
					break
				case "vitamin":
					this.showVitaminsModal = !this.showVitaminsModal
					break
			}
		},
		cleanSpecimenCheck(specimen_id) {
			let index = this.rows.findIndex((element) => specimen_id == element.specimen.id)
			this.rows[index]._check = false
		},
		dateDiffFormat(init, end) {
			init = moment(init).format("YYYY-MM-DD")
			end = moment(end).format("YYYY-MM-DD")
			let start = moment(init)
			let today = moment(end)
			let daysDiff = today.diff(start, "days")
			return `${daysDiff} dia(s)`
		},
		onBlock(e) {
			this.curBlock = e
			this.getRows()
		},
		daysToAssign(date) {
			if (moment(date).isSame(new Date(), "day")) {
				return `7 dias para asignar`
			}
			let today = moment()
			let start = moment(new Date(date)).format("YYYY-MM-DD")
			let daysDiff = today.diff(start, "days")
			return daysDiff < 7
			// if (daysDiff >= 7) {
			// 	return false;
			// }
			// return `quedan: ${7 - daysDiff} dia(s)`;
		},
		daysToAssignStr(date) {
			if (moment(date).isSame(new Date(), "day")) {
				return `Pendiente (7d)`
			}
			let today = moment()
			let start = moment(new Date(date)).format("YYYY-MM-DD")
			let daysDiff = today.diff(start, "days")
			if (daysDiff >= 7) {
				return `Pendiente: (-${daysDiff - 7} d)`
			}
			return `Pendiente:  (${7 - daysDiff} d)`
		},
		rowInDanger(row) {
			// disable if is not in process tab
			if (this.status == 4) return
			// check if has no weight and was created more than 7 days ago
			if (!row.weight_last && !this.daysToAssign(row.created.date)) {
				return "danger"
			}
			if (!row.deworn_last && !this.daysToAssign(row.created.date)) {
				return "danger"
			}
			return null
		},
		colorizeSpecimenStatus(status) {
			switch (status) {
				case 1:
					return "success"
				case 3:
					return "danger"
				case 13:
					return "warning"

				default:
					return "secondary"
			}
		},
		async initQualityPendings() {
			const params = {
				block: this.status == 1 ? this.curBlock : null,
				start_date: this.season.start,
				end_date: this.season.end,
			}
			const { data } = await preparacionService.getCountTopesPending(params)
			if (data.length > 0) {
				this.pendingsTotal = data[0].quantity
			}
		},
		setLineSpecimen(row) {
			console.log({ row })
			// return
			this.showLineSpecimen = {
				show: true,
				specimen: row.specimen,
				training_id: row.training_id,
			}
		},
		async getTrainerOpts() {
			this.isLoading = true
			const res = await preparacionService.getTrainerOpts()
			this.trainerOpts = res.map((opt) => ({ id: opt.id, label: opt.name }))
			this.trainerOpts.unshift({ id: null, label: "Todos" })
			this.isLoading = false
		},
		openTripDetail(data) {
			this.rowInfo = data
			if (data.counter_trip) {
				this.showTripModal = true
			}
		},
		openModalWeightMassive() {
			let selecteds
			selecteds = this.rows.filter((row) => row._check)
			this.selectedSpecimens = selecteds
			if (selecteds.length == 0) {
				this.showToast(
					"danger",
					"top-right",
					"Preparacion",
					"AlertCircleIcon",
					`No hay ejemplares seleccionados`
				)
				return
			}

			this.showWeight = true
		},
	},
	created() {
		this.getRows()
		this.getTrainerOpts()
	},
	watch: {
		trigger(val) {
			this.getRows()
		},
		"pagination.curPage"(val) {
			this.getRows()
		},
		// status (val) {
		// 	this.getRows()
		// },
	},
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.bg-info-light {
	background: lighten($color: $blue, $amount: 25%);
	color: $dark;
}

.bg-success-light {
	background: lighten($color: $green, $amount: 25%);
	color: $dark;
}

.bg-danger-light {
	background: lighten($color: $red, $amount: 25%);
	color: $dark;
}
</style>

<style lang="scss">
.vue-star-rating {
	display: flex !important;
	align-items: center !important;
	justify-content: center;

	.vue-star-rating-rating-text {
		display: none;
	}
}
</style>
<style lang="scss" scoped>
.star-score {
	color: var(--primary);
	margin-top: -5px;
}

.incidences-counter {
	position: absolute;
	top: -0.4rem;
	left: 1.5rem;
	height: 1.2rem;
	min-width: 1.2rem;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	background: var(--primary);
	border-radius: 0.2rem;
	font-weight: bolder;
	font-size: 12px;
}

.icon-disabled {
	pointer-events: none;
	opacity: 0.5;
}
</style>
