<template>
	<b-modal centered hide-footer :title="titleModal" size="lg" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Registrado por</b-th>
						<b-th>Suplemento</b-th>
						<b-th>Tipo</b-th>
						<b-th>Días</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>
							<p class="mb-0">{{ row.created_by.user }}</p>
							<p class="mb-0">{{ row.created_by.date | myGlobalDayShort }}</p>
						</b-td>
						<b-td>{{ row.name }}</b-td>
						<b-td>
							<b-badge :variant="row.type == 'frecuente' ? 'primary' : 'secondary'">{{
								row.type
							}}</b-badge>
						</b-td>
						<b-td>
							<p>{{ row.days && row.days.join(',') }}</p>
							<p v-if="row.type == 'ocasional'">---</p>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js";

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	computed: {
		titleModal() {
			return this.info.specimen
				? `Suplementos / ${this.info.specimen.alias ? this.info.specimen.alias : ""} (${
						this.info.specimen.plate
				  })`
				: null;
			// return '';
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.$emit("closing");
		},
		async getRows() {
			this.isLoading = true;
			const { data } = await preparacionService.getTrackingVitamins({ training_id: this.info.training_id });
			const diasSemana = {
				1: 'Lu',
				2: 'Ma',
				3: 'Mi',
				4: 'Ju',
				5: 'Vi',
				6: 'Sa',
				7: 'Do'
				};
			data.forEach(element => {
				if(element.days){
					element.days = element.days.sort().map(index => diasSemana[index])
				}
			});
			this.rows = data;
			this.isLoading = false;
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows();
				this.isActive = val;
			}
		},
	},
};
</script>
