<template>
	<b-modal centered hide-footer size="sm" :title="titleModal" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Creado por</b-th>
						<b-th>Fecha</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.created_by.date">
						<b-td>{{ row.created_by.user }}</b-td>
						<b-td>{{ row.created_by.date | myGlobalDayShort }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js";

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	computed: {
		titleModal() {
			return this.info.specimen
				? `Desparasitación / ${this.info.specimen.alias || ''} (${this.info.specimen.plate})`
				: null;
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.$emit("closing");
		},
		async getRows() {
			this.isLoading = true;
			const { data } = await preparacionService.getTrackingDeworn({ training_id: this.info.training_id });
			this.rows = data;
			this.isLoading = false;
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows();
				this.isActive = val;
			}
		},
	},
};
</script>
