<template>
	<b-modal centered size="" title="Agregar un ejemplar a preparación" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!-- <b-form-group label="1. Seleccionar dias">
				<b-input-group>
					<b-form-select v-model="block" :options="blockOpts" />
				</b-input-group>
			</b-form-group> -->
			<b-form-group label="1. Buscar ejemplar">
				<b-input-group>
					<b-form-input placeholder="Ingrese placa o alias" v-model="search" @keyup.enter="searchSpecimen" />
					<b-input-group-append>
						<b-button variant="outline-primary" @click="searchSpecimen">
							<feather-icon icon="SearchIcon" />
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
			<b-form-group label="Ejemplares seleccionados (click para quitar)" class="bg-specimen-add">
				<b-row>
					<b-col
						class="text-center my-1 d-flex align-items-center justify-content-center"
						cols="6"
						v-for="specimen in selectedSpecimens"
						:key="specimen.id"
					>
						<div class="position-relative clickable hoverTrigger" @click="removeSelected(specimen)">
							<div class="deleteOverlay">
								<feather-icon icon="Trash2Icon" size="35" />
							</div>
							<SpecimenPlate :specimen="specimen" style="pointer-events: none" />
						</div>
					</b-col>
					<b-col class="text-center" v-if="selectedSpecimens.length == 0">
						No hay ejemplares seleccionados
					</b-col>
				</b-row>
			</b-form-group>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="sendSpecimens">INGRESAR A PREPARACION</b-button>
		</template>
		<b-modal title="Seleccione un ejemplar" centered hide-footer size="" v-model="creationModal.active">
			<b-row>
				<b-col cols="6" v-for="specimen in creationModal.specimenOpts" :key="specimen.id">
					<div
						class="clickable mb-1"
						:class="{ alreadySelected: specimen.is_training }"
						@click="selectSpecimen(specimen)"
					>
						<SpecimenPlate :specimen="specimen" style="pointer-events: none" />
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import settingsService from "@/services/preparacion/settings.service"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object, // Preparation season
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		search: "",
		block: null,
		blockOpts: [],
		selectedSpecimens: [],
		creationModal: {
			active: false,
			specimenOpts: [],
			loading: false,
		},
	}),
	methods: {
		handleHidden() {
			this.isActive = false
			this.search = ""
			this.selectedSpecimens = []
			this.$emit("closing")
		},
		async getBlocks() {
			this.isLoading = true
			const { data } = await settingsService.getBlocks({ season_id: this.info.id })
			this.blockOpts = data.map((block) => ({
				value: block.training_block_id,
				text: block.name,
			}))
			if (this.blockOpts.length != 0) this.block = this.blockOpts[0].value
			this.isLoading = false
		},
		async searchSpecimen() {
			this.isLoading = true
			const { data } = await preparacionService.getSpecimensForTraining({
				search: this.search,
				start_date: this.info.start,
				end_date: this.info.end,
			})
			this.search = ""
			if (data.length == 0) {
				this.showToast("warning", "top-right", "Sin resultados", "SuccessIcon", "Ejemplar no disponible")
				this.isLoading = false
				return
			}
			if (data.length == 1) {
				if (data[0].is_training == 1) {
					this.showToast(
						"info",
						"top-right",
						"Preparación",
						"InfoIcon",
						"El ejemplar ya tiene una preparación activa"
					)
					this.isLoading = false
					return
				}
				if (!this.selectedSpecimens.some((it) => it.id == data[0].id)) {
					this.selectedSpecimens.push(data[0])
					this.showToast("info", "top-right", "Preparación", "InfoIcon", "El ejemplar se agregó con éxito")
				} else {
					this.showToast(
						"warning",
						"top-right",
						"Preparación",
						"InfoIcon",
						"El ejemplar ya fue agregado anteriormente"
					)
				}

				this.isLoading = false
				return
			}
			this.creationModal.specimenOpts = data
			this.creationModal.active = true
			this.isLoading = false
		},
		selectSpecimen(specimen) {
			if (specimen.is_training == 1) {
				this.showToast(
					"info",
					"top-right",
					"Preparación",
					"InfoIcon",
					"El ejemplar ya tiene una preparación activa"
				)
				return
			}
			let duplicated = this.selectedSpecimens.some((selected) => selected.id == specimen.id)
			if (duplicated) {
				this.showToast(
					"warning",
					"top-right",
					"Duplicado",
					"InfoIcon",
					"El ejemplar ya se encuentra seleccionado"
				)
				return
			}
			this.selectedSpecimens.push(specimen)
			this.creationModal.specimenOpts = []
			this.creationModal.active = false
		},
		removeSelected(specimen) {
			let idx = this.selectedSpecimens.findIndex((selected) => selected.id == specimen.id)
			if (idx == -1) return
			this.selectedSpecimens.splice(idx, 1)
		},
		async sendSpecimens() {
			// if (!this.block) {
			// 	this.showToast(
			// 		"danger",
			// 		"top-right",
			// 		"Preparacion",
			// 		"InfoIcon",
			// 		"No hay un bloque de dias seleccionado"
			// 	)
			// 	return
			// }
			if (this.selectedSpecimens.length == 0) {
				this.showToast("danger", "top-right", "Preparacion", "InfoIcon", "No hay ningun ejemplar seleccionado")
				return
			}
			this.isLoading = true
			let names = this.selectedSpecimens.map((specimen) => specimen.alias || specimen.plate).join(", ")
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se agregarán los ejemplares <span class="font-weight-bolder">${names}</span> a preparación.</p>
				`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			let specimens = this.selectedSpecimens.map((specimen) => specimen.id)
			await preparacionService.insertTraining({ specimens, block_id: this.block })
			this.isLoading = false
			this.$emit("reload")
			this.handleHidden()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getBlocks()
				this.isActive = val
			}
		},
	},
}
</script>

<style scoped lang="scss">
.alreadySelected {
	background: rgba(0, 0, 0, 0.2);
}

.deleteOverlay {
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: black;
	opacity: 0;
}
.hoverTrigger {
	padding: 0.5rem;
	height: 110px;
	display: flex;
	align-items: center;
	justify-content: center;
	.deleteOverlay {
		transition: 0.3s all ease-in-out;
	}
}

.hoverTrigger:hover .deleteOverlay {
	opacity: 0.8;
	background: rgba(255, 0, 0, 0.2);
	border-radius: 4px;
	box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
}

.bg-specimen-add {
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
}
</style>
