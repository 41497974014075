<template>
	<b-modal centered size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<template v-if="isMultiple">
				<b-row style="width: 80%; margin: auto">
					<b-col md="6">
						<b-form-group label="Suplemento">
							<b-form-select :options="vitaminOpts" @change="masterChange($event, 'v')" />
						</b-form-group>
					</b-col>
					<b-col md="6">
						<b-form-group label="Tipo">
							<b-form-radio-group val @change="masterChange($event, 't')" :checked="1">
								<b-form-radio :value="1">Frecuente</b-form-radio>
								<b-form-radio :value="2">Ocasional</b-form-radio>
							</b-form-radio-group>
						</b-form-group>
					</b-col>
				</b-row>
				<div>
					<div class="border-bottom-dark">
						<feather-icon
							class="clickable"
							icon="ChevronUpIcon"
							size="25"
							@click="showTable = false"
							v-if="showTable"
							v-b-tooltip.hover
							title="Ocultar seleccionados"
						/>
						<feather-icon
							class="clickable"
							icon="ChevronDownIcon"
							size="25"
							@click="showTable = true"
							v-b-tooltip.hover
							title="Mostrar seleccionados"
							v-else
						/>
						<b class="float-right">Seleccionados ({{ rows.length }})</b>
					</div>
				</div>
			</template>
			<ValidationObserver ref="form">
				<b-table-simple v-show="showTable" sticky-header="40vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Ejemplar</b-th>
							<b-th>Suplemento</b-th>
							<b-th>Tipo</b-th>
							<b-th>Días</b-th>
							<b-th v-if="isMultiple">Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="row in rows" :key="row.training_id">
							<b-td>
								<b-badge>{{ row.specimen.alias || row.specimen.plate }}</b-badge>
							</b-td>
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<b-form-select
										:class="errors[0] ? 'is-invalid' : ''"
										v-model="row._vitamin"
										:options="vitaminOpts"
									/>
								</ValidationProvider>
							</b-td>
							<b-td>
								<b-form-radio-group v-model="row._vitaminType">
									<b-form-radio :value="1">Frecuente</b-form-radio>
									<b-form-radio :value="2">Ocasional</b-form-radio>
								</b-form-radio-group>
							</b-td>
							<b-td>
								<template v-if="row._vitaminType == 1">
									<ValidationProvider rules="required" v-slot="{ errors }">
										<v-select
											v-model="row.days"
											label="text"
											style="width: 10rem"
											multiple
											appendToBody
											:clearable="false"
											:options="days"
											:reduce="(opt) => opt.value"
											:calculatePosition="positionDropdown"
											:class="{ 'border-danger rounded': !!errors[0] }"
										/>

										<small v-if="errors[0]" class="text-danger text-small-1">{{ errors[0] }}</small>
									</ValidationProvider>
								</template>
								<p v-if="row._vitaminType == 2">---</p>
							</b-td>
							<td v-if="isMultiple">
								<b-button
									class="btn-icon"
									variant="flat-danger"
									size="sm"
									@click="deleteSpecimenSelected(row)"
								>
									<feather-icon class="cursor-pointer" size="20" icon="TrashIcon" />
								</b-button>
							</td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

export default {
	props: {
		show: Boolean,
		info: Array,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		selected: null,
		type: 1,
		vitaminOpts: [],
		showTable: false,
		days: [
			{ text: "Lunes", value: 1 },
			{ text: "Martes", value: 2 },
			{ text: "Miercoles", value: 3 },
			{ text: "Jueves", value: 4 },
			{ text: "Viernes", value: 5 },
			{ text: "Sabado", value: 6 },
			{ text: "Domingo", value: 7 },
		],
	}),
	computed: {
		isMultiple() {
			return this.rows.length > 1
		},
		title() {
			if (!this.rows[0]) return
			if (this.isMultiple) return "Registrar suplemento masivamente"
			return `Registrar suplemento al ejemplar ${this.rows[0].specimen.alias || this.rows[0].specimen.plate}`
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.selected = null
			this.showTable = false
			this.vitaminOpts = []
			this.rows = []
			this.$emit("closing")
		},
		async getVitaminOptions() {
			this.isLoading = true
			const { data } = await preparacionSettingService.getVitamins({ status: 1 })
			this.vitaminOpts = data.map((opt) => ({
				value: opt.id,
				text: opt.name,
			}))
			this.isLoading = false
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async save() {
			if (!(await this.$refs.form.validate())) {
				if (!this.showTable) {
					this.showToast(
						"warning",
						"top-right",
						"Suplementos",
						"AlertCircleIcon",
						"Hay registros que faltan asignar"
					)
				}
				return
			}
			this.isLoading = true
			let html
			if (this.isMultiple) {
				html = `
					<p class="mb-0">Se registrará suplemento para los ejemplares <span class="font-weight-bolder">${this.rows.map(
						(it) => it.specimen.plate
					)}</span>.</p>
				`
			} else {
				html = `
					<p class="mb-0">Se registrará nueva suplemento al ejemplar <span class="font-weight-bolder">${
						this.rows[0].specimen.alias || this.rows[0].specimen.plate
					}</span></p>
				`
			}
			const { isConfirmed } = await this.showConfirmSwal({ html })
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			let trainings = this.rows.map((row) => ({
				id: row.training_id,
				vitamin_id: row._vitamin,
				type: row._vitaminType,
				days: row.days,
			}))
			await preparacionService.insertVitamin({ trainings })
			this.isLoading = false
			this.$emit("refresh")
			this.handleHidden()
		},
		deleteSpecimenSelected(row) {
			this.rows.forEach((element, index) => {
				if (element.specimen.id == row.specimen.id) {
					this.$emit("cleanCheck", row.specimen.id)
					this.rows.splice(index, 1)
				}
			})
			if (this.rows.length == 0) {
				this.handleHidden()
			}
		},

		masterChange(e, type) {
			if (type == "v") {
				this.rows.forEach((row) => {
					row._vitamin = e
				})
				return
			}
			this.rows.forEach((row) => {
				row._vitaminType = e
			})
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getVitaminOptions()
				this.isActive = val
				this.rows = this.info.map((row) => ({
					...row,
					_vitamin: null,
					_vitaminType: row.vitamin_last == null ? 1 : 2,
				}))
				//
				if (!this.isMultiple) {
					this.showTable = true
				}
			}
		},
	},
}
</script>
