var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","size":"xmd","title":"Agregar topes"},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.insertTopes}},[_vm._v("Guardar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('div',{staticClass:"mx-1 mt-1"},[_c('b-row',{staticClass:"container-header-table"},[_c('b-col',{staticClass:"header-table",attrs:{"cols":"1"}},[_c('p',{staticClass:"mb-0"},[_vm._v("#")])]),_c('b-col',{staticClass:"header-table",attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Tipo")])]),_c('b-col',{staticClass:"header-table",attrs:{"cols":"2"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Categoria")])]),_c('b-col',{staticClass:"header-table",attrs:{"cols":"3"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Local")])]),_c('b-col',{staticClass:"header-table",attrs:{"cols":"3"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Rival")])]),_c('b-col',{staticClass:"header-table",attrs:{"cols":"1"}},[_c('p',{staticClass:"mb-0"},[_vm._v("Acciones")])])],1),_c('ValidationObserver',{ref:"formAddTopes"},_vm._l((_vm.topes),function(item,index){return _c('b-row',{key:index,staticClass:"container-body-table"},[_c('b-col',{staticClass:"body-table",staticStyle:{"align-items":"center !important"},attrs:{"cols":"1"}},[_c('p',{staticClass:"mb-0 font-weight-bold"},[_vm._v(_vm._s(index + 1))])]),_c('b-col',{staticClass:"body-table",attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-radio-group',{attrs:{"options":_vm.arrayTypes,"name":("type-select-topes-" + index)},on:{"input":function($event){return _vm.changeType(index)}},model:{value:(item.type_id),callback:function ($$v) {_vm.$set(item, "type_id", $$v)},expression:"item.type_id"}})],1),(errors[0])?_c('small',{staticClass:"text-input-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',{staticClass:"body-table",attrs:{"cols":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(item.type_id == 1)?[_c('b-form-group',[_c('b-form-radio-group',{attrs:{"options":[
										{
											text: 'Pollo',
											value: 1,
										},
										{
											text: 'Ejemplar',
											value: 2,
										} ],"disabled":item.type_id == null,"name":("select-type-rival-" + index)},on:{"input":function($event){return _vm.changeCategory(index)}},model:{value:(item.type_rival),callback:function ($$v) {_vm.$set(item, "type_rival", $$v)},expression:"item.type_rival"}})],1)]:[_c('b-form-group',[_c('b-form-radio',{attrs:{"disabled":item.type_id == null,"value":3},model:{value:(item.type_rival),callback:function ($$v) {_vm.$set(item, "type_rival", $$v)},expression:"item.type_rival"}},[_vm._v(" Criador ")])],1)],(errors[0])?_c('small',{staticClass:"text-input-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',{staticClass:"body-table p-1",attrs:{"cols":"3"}},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Buscar","label-class":"font-weight-bolder"}},[_c('b-input-group',{staticClass:"w-100"},[_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.specimens,"clearable":false,"label":"plate","reduce":function (option) { return option; },"transition":"","placeholder":"Buscar por placa o alias"},on:{"search":function (search, loading) { return _vm.getRivals({
													search: search,
													type_rival: 1,
													loading: loading,
												}); },"input":function (e) { return _vm.selectSpecimen(e, index, 'local'); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.plate)+" "+_vm._s(option.alias ? ("(" + (option.alias) + ")") : "")+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.plate)+" "+_vm._s(option.alias ? ("(" + (option.alias) + ")") : "")+" ")])]}}],null,true),model:{value:(item.specimenLocal),callback:function ($$v) {_vm.$set(item, "specimenLocal", $$v)},expression:"item.specimenLocal"}},[_c('template',{slot:"no-options"},[_vm._v("Sin ejemplares encontradas")])],2)],1)],1),(errors[0])?_c('small',{staticClass:"text-input-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',{staticClass:"body-table p-1",attrs:{"cols":"3"}},[_c('ValidationProvider',{staticClass:"w-100",attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
												var errors = ref.errors;
return [_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Buscar","label-class":"font-weight-bolder"}},[_c('b-input-group',{staticClass:"w-100"},[(item.type_rival == 2)?_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.specimens,"clearable":false,"label":"plate","reduce":function (option) { return option; },"transition":"","placeholder":"Buscar por placa o alias"},on:{"search":function (search, loading) { return _vm.getRivals({
													search: search,
													type_rival: 1,
													loading: loading,
												}); },"input":function (e) { return _vm.selectSpecimen(e, index, 'rival'); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.plate)+" "+_vm._s(option.alias ? ("(" + (option.alias) + ")") : "")+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.plate)+" "+_vm._s(option.alias ? ("(" + (option.alias) + ")") : "")+" ")])]}}],null,true),model:{value:(item.specimen),callback:function ($$v) {_vm.$set(item, "specimen", $$v)},expression:"item.specimen"}},[_c('template',{slot:"no-options"},[_vm._v("Sin ejemplares encontradas")])],2):_vm._e(),(item.type_rival == 1)?_c('v-select',{staticClass:"w-100",attrs:{"options":_vm.chicks,"clearable":false,"label":"correlative","reduce":function (option) { return option; },"transition":"","placeholder":"Buscar por cintillo"},on:{"search":function (search, loading) { return _vm.getRivals({
													search: search,
													type_rival: 2,
													loading: loading,
												}); }},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-center"},[_vm._v(" "+_vm._s(option.correlative)+" ")])]}},{key:"selected-option",fn:function(option){return [_c('div',{staticClass:"selected d-center"},[_vm._v(" "+_vm._s(option.correlative)+" ")])]}}],null,true),model:{value:(item.chick),callback:function ($$v) {_vm.$set(item, "chick", $$v)},expression:"item.chick"}},[_c('template',{slot:"no-options"},[_vm._v("Sin pollos encontradas")])],2):_vm._e(),(item.type_rival == 3)?_c('v-select',{staticClass:"w-100 bg-white",attrs:{"clearable":false,"label":"name","options":_vm.rivals},model:{value:(item.rival),callback:function ($$v) {_vm.$set(item, "rival", $$v)},expression:"item.rival"}}):_vm._e(),(!item.type_rival)?_c('p',{staticClass:"mb-0"},[_vm._v("Sin seleccionar categoria")]):_vm._e()],1)],1),(errors[0])?_c('small',{staticClass:"text-input-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-col',{staticClass:"body-table",staticStyle:{"align-items":"center !important"},attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.removeSelected(index)}}},[_c('FeatherIcon',{staticClass:"x-icon-row text-white",attrs:{"icon":"XIcon"}})],1)],1)])],1)}),1),_c('b-row',{staticClass:"container-body-table"},[_c('b-col',{staticClass:"btn-add-tope",staticStyle:{"align-items":"center !important"},attrs:{"cols":"11"}}),_c('b-col',{staticClass:"body-table",staticStyle:{"align-items":"center !important"},attrs:{"cols":"1"}},[_c('div',{staticClass:"d-flex flex-column justify-center"},[_c('b-button',{staticClass:"mt-1 mb-1",attrs:{"variant":"success","size":"sm"},on:{"click":_vm.addRow}},[_c('FeatherIcon',{staticClass:"x-icon-row text-white",attrs:{"icon":"PlusIcon"}})],1)],1)])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }