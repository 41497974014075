<template>
	<b-modal centered hide-footer size="xmd" :title="titleModal" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Rival</b-th>
						<b-th>Tipo</b-th>
						<b-th>Video</b-th>
						<b-th>Calificacion</b-th>
						<b-th>Registrado por</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td>
							<div v-if="row.type_rival == 'chick'" class="d-flex justify-content-center">
								<b-badge class="d-block" variant="primary">
									{{ row.chick.correlative }}
								</b-badge>
							</div>
							<SpecimenPlate v-if="row.type_rival == 'specimen'" :specimen="row.specimen" />
							<p v-if="row.type_rival == 'other'">{{ row.other_rival.name }}</p>
						</b-td>
						<b-td>
							<b-badge>
								{{
									(row.type_rival == "specimen"
										? "ejemplar"
										: row.type_rival == "chick"
										? "chick"
										: "rival"
									).toUpperCase()
								}}
							</b-badge>
						</b-td>

						<!-- <b-td>
							<div class="d-flex flex-column">
								<small
									class="d-inline-block mb-1 text-nowrap text-sm text-success"
									v-for="val in row._qualities"
									:key="val[0]"
								>
									{{ val[0] }}
									<b-badge variant="success">{{ val[1] }}</b-badge>
								</small>
								<p v-if="row._qualities.length == 0" class="mb-0">---</p>
							</div>
						</b-td> -->
						<!-- <b-td>
							<div class="d-flex flex-column">
								<small
									class="d-inline-block mb-1 text-nowrap text-sm text-danger"
									v-for="val in row._defects"
									:key="val[0]"
								>
									{{ val[0] }}
									<b-badge variant="danger">{{ val[1] }}</b-badge>
								</small>

								<p v-if="row._defects.length == 0" class="mb-0">---</p>
							</div>
						</b-td> -->
						<b-td>
							<b-button
								:variant="row.video && row.video.status == 1 ? 'flat-warning' : 'flat-success'"
								class="p-0 m-0"
								@click="validActiveToVideoTope(row)"
								v-if="row.video"
								v-b-tooltip.hover
								:title="row.video && row.video.status == 1 ? 'Actualizando video' : 'Video listo'"
							>
								<b-avatar
									:src="row.video.status == 2 ? row.video.thumb : null"
									rounded
									size="80"
									icon="film"
									badge
									badge-variant="danger"
								>
									<template #badge>{{ row.video.duration }}</template>
								</b-avatar>
							</b-button>
							<b-badge v-else variant="danger">Sin video</b-badge>
							<!-- <p v-else class="mb-0 font-small-3">
								<UploadVideoTope
									v-if="isAdmin || isPreparador"
									:title="`${info.specimen.alias || ''} (${info.specimen.plate}) `"
									:info="row"
									@refresh="
										getRows()
										$emit('refresh')
									"
								/>
							</p> -->
						</b-td>
						<b-td>
							<div class="d-flex justify-content-center">
								<span class="font-weight-bold text-primary">
									{{ Number(row.score).toFixed(2) }}
								</span>
								<feather-icon class="text-primary" style="margin-left: 5px" size="16" icon="StarIcon" />
							</div>
						</b-td>
						<b-td>
							{{ row.created_by.user }}
							<br />
							<small>{{ row.created_by.date | myGlobalDayShort }}</small>
						</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
		<!-- :title="`Ver video / ${info.specimen.alias ? info.specimen.alias : ''} (${info.specimen.plate}) vs ${
				(modalReview.info.specimen ? modalReview.info.specimen.alias : '') ||
				(modalReview.info.chick ? modalReview.info.chick.cintillo : '') ||
				(modalReview.info.other_rival ? modalReview.info.other_rival.name : '')
			}`" -->
		<b-modal
			centered
			no-close-on-backdrop
			hide-footer
			size="sm"
			v-model="modalReview.show"
			@show="getOpts"
			@hidden="clearReview"
		>
			<b-overlay :show="modalReview.loading">
				<b-form-group v-if="modalReview.info && modalReview.info.video">
					<div class="position-relative" style="width: 100%; height: 18rem">
						<VideoCloudflare v-if="modalReview.info.video.status == 2" :uid="modalReview.info.video.uid" />
						<p v-else class="mb-0 text-center">Cargando video</p>
					</div>
				</b-form-group>
			</b-overlay>
		</b-modal>
	</b-modal>
</template>

<script>
import { mapActions } from "vuex"
import preparacionService from "@/services/preparacion/default.service.js"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

import VideoCloudflare from "../../tournaments/VideoCloudflare.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import QualifyTope from "./QualifyTope.vue"
import UploadVideoTope from "./UploadVideoTope.vue"
import moment from "moment"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	components: { SpecimenPlate, VideoCloudflare, QualifyTope, UploadVideoTope },
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
		//
		modalReview: {
			show: false,
			loading: false,
			info: {},
		},
		qualityOpts: [],
		defectOpts: [],
		rating: 0,
		qualities: null,
		defects: null,
	}),
	computed: {
		titleModal() {
			return this.info.specimen ? `Topes / ${this.info.specimen.alias || ""} (${this.info.specimen.plate})` : null
		},
	},
	mounted() {
		// console.log('Hola');
	},
	methods: {
		...mapActions("header_book", ["toggleModal", "setIndexImage", "setTextImage", "setImages"]),
		handleHidden() {
			this.isActive = false
			this.modalReview = {
				show: false,
				info: {},
			}
			this.$emit("closing")
		},
		async getRows() {
			this.isLoading = true
			console.log("info: ", this.info)
			const { data } = await preparacionService.getTopesBySpecimenTracking({ specimen_id: this.info.specimen.id })
			this.rows = data
			this.isLoading = false
		},
		async getOpts() {
			this.modalReview.loading = true
			const responses = [
				preparacionSettingService.getQualities({ status: 1 }),
				preparacionSettingService.getDefects({ status: 1 }),
			]
			const data = await Promise.all(responses)
			this.qualityOpts = data[0].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.defectOpts = data[1].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.modalReview.loading = false
		},
		async showTope(row) {
			this.isLoading = true
			const { data } = await preparacionService.getRoutineTope({ confrontation_id: row.confrontation_id })
			this.modalTope.show = true
			this.modalTope.title = `Tope / ${this.info.specimen.alias || this.info.specimen.plate}`
			this.modalTope.info = data
			this.isLoading = false
		},
		colorizePoints(points) {
			if (points == 2.5) return "bg-info-light"
			else if (points == null) return "bg-secondary-light"
			else if (points > 2.5) return "bg-success-light"
			else if (points < 2.5) return "bg-danger-light"
		},
		clearReview() {
			this.rating = 0
			this.qualities = null
			this.defects = null
		},
		setDateTol(date) {
			return moment(date).format("MM-DD-YYYY")
		},
		showTopeVideo(row) {
			// console.log("row", row)
			this.setImages([
				{
					...row.video,
					type: "video",
					url: row.video.uid,
					text: `${this.info.specimen.alias || this.info.specimen.plate} vs ${
						(row.specimen && (row.specimen.alias || row.specimen.plate)) ||
						(row.chick && row.chick.correlative) ||
						(row.other_rival && row.other_rival.name)
					}`,
					specimen: { id: this.info.specimen.id },
				},
			])
			this.setTextImage({
				title: `${this.info.specimen.alias || this.info.specimen.plate} vs ${
					(row.specimen && (row.specimen.alias || row.specimen.plate)) ||
					(row.chick && row.chick.correlative) ||
					(row.other_rival && row.other_rival.name)
				}`,
				url: `/ejemplares/detalle/${this.info.specimen.id}`,
			})
			this.setIndexImage(0)
			this.toggleModal()
			// this.modalReview.show = !this.modalReview.show;
			// this.modalReview.info = row;
		},
		async validActiveToVideoTope(row) {
			if (row.video == null) return
			this.isPreloading()
			const {
				data: { valid },
			} = await preparacionService.validActiveToVideoById({ id: row.video.id })
			// console.log("resp", valid);
			this.isPreloading(false)

			if (valid) {
				this.showTopeVideo(row)
			} else {
				this.showToast(
					"warning",
					"top-right",
					"Preparación",
					"WarningIcon",
					"El video estará disponible en unos momentos"
				)
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows()
				this.isActive = val
			}
		},
	},
}
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";

.bg-info-light {
	background: lighten($color: $blue, $amount: 25%);
	color: $dark;
}

.bg-success-light {
	background: lighten($color: $green, $amount: 25%);
	color: $dark;
}

.bg-danger-light {
	background: lighten($color: $red, $amount: 25%);
	color: $dark;
}

// .position-relative {
// 	// display: flex;
// 	align-items: center;
// 	justify-content: center;
// }
.border-footer-average {
	width: 80%;
	height: 3px;
	background: #7367f0;
	margin: 0.3rem auto;
	// margin: auto;
}
</style>

<style>
.b-avatar-badge.badge-danger {
	font-size: calc(12px) !important;
}
.star-score {
	color: var(--primary) !important;
}
.star-score--filled {
	fill: var(--primary);
}
</style>
