<template>
	<b-modal no-close-on-backdrop centered size="xmd" title="Agregar topes" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<div class="mx-1 mt-1">
				<b-row class="container-header-table">
					<b-col cols="1" class="header-table">
						<p class="mb-0">#</p>
					</b-col>
					<b-col cols="2" class="header-table">
						<p class="mb-0">Tipo</p>
					</b-col>
					<b-col cols="2" class="header-table">
						<p class="mb-0">Categoria</p>
					</b-col>
					<b-col cols="3" class="header-table">
						<p class="mb-0">Local</p>
					</b-col>
					<b-col cols="3" class="header-table">
						<p class="mb-0">Rival</p>
					</b-col>

					<b-col cols="1" class="header-table">
						<p class="mb-0">Acciones</p>
					</b-col>
				</b-row>

				<ValidationObserver ref="formAddTopes">
					<b-row class="container-body-table" v-for="(item, index) in topes" :key="index">
						<b-col cols="1" class="body-table" style="align-items: center !important">
							<p class="mb-0 font-weight-bold">{{ index + 1 }}</p>
						</b-col>
						<b-col cols="2" class="body-table">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<b-form-group>
									<b-form-radio-group v-model="item.type_id" :options="arrayTypes"
										:name="`type-select-topes-${index}`"
										@input="changeType(index)"></b-form-radio-group>
								</b-form-group>
								<small class="text-input-error" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
						<b-col cols="2" class="body-table">
							<ValidationProvider rules="required" v-slot="{ errors }">
								<template v-if="item.type_id == 1">
									<b-form-group>
										<b-form-radio-group v-model="item.type_rival" :options="[
											{
												text: 'Pollo',
												value: 1,
											},
											{
												text: 'Ejemplar',
												value: 2,
											},
										]" :disabled="item.type_id == null" :name="`select-type-rival-${index}`"
											@input="changeCategory(index)"></b-form-radio-group>
									</b-form-group>
								</template>
								<template v-else>
									<b-form-group>
										<b-form-radio :disabled="item.type_id == null" v-model="item.type_rival"
											:value="3">
											Criador
										</b-form-radio>
									</b-form-group>
								</template>
								<small class="text-input-error" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
						<b-col cols="3" class="body-table p-1">
							<ValidationProvider class="w-100" rules="required" v-slot="{ errors }">
								<b-form-group class="w-100" label="Buscar" label-class="font-weight-bolder">
									<b-input-group class="w-100">
										<v-select v-model="item.specimenLocal" :options="specimens" :clearable="false"
											label="plate" :reduce="(option) => option" transition=""
											placeholder="Buscar por placa o alias" @search="(search, loading) =>
													getRivals({
														search,
														type_rival: 1,
														loading,
													})
												" class="w-100" @input="(e) => selectSpecimen(e, index, 'local')">
											<template slot="no-options">Sin ejemplares encontradas</template>

											<template slot="option" slot-scope="option">
												<div class="d-center">
													{{ option.plate }} {{ option.alias ? `(${option.alias})` : "" }}
												</div>
											</template>
											<template slot="selected-option" slot-scope="option">
												<div class="selected d-center">
													{{ option.plate }} {{ option.alias ? `(${option.alias})` : "" }}
												</div>
											</template>
										</v-select>
									</b-input-group>
								</b-form-group>
								<small class="text-input-error" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
						<b-col cols="3" class="body-table p-1">
							<ValidationProvider class="w-100" rules="required" v-slot="{ errors }">
								<b-form-group class="w-100" label="Buscar" label-class="font-weight-bolder">
									<b-input-group class="w-100">
										<v-select v-if="item.type_rival == 2" v-model="item.specimen"
											:options="specimens" :clearable="false" label="plate"
											:reduce="(option) => option" transition=""
											placeholder="Buscar por placa o alias" @search="(search, loading) =>
													getRivals({
														search,
														type_rival: 1,
														loading,
													})
												" class="w-100" @input="(e) => selectSpecimen(e, index, 'rival')">
											<template slot="no-options">Sin ejemplares encontradas</template>

											<template slot="option" slot-scope="option">
												<div class="d-center">
													{{ option.plate }} {{ option.alias ? `(${option.alias})` : "" }}
												</div>
											</template>
											<template slot="selected-option" slot-scope="option">
												<div class="selected d-center">
													{{ option.plate }} {{ option.alias ? `(${option.alias})` : "" }}
												</div>
											</template>
										</v-select>
										<v-select v-if="item.type_rival == 1" v-model="item.chick" :options="chicks"
											:clearable="false" label="correlative" :reduce="(option) => option"
											transition="" placeholder="Buscar por cintillo" @search="(search, loading) =>
													getRivals({
														search,
														type_rival: 2,
														loading,
													})
												" class="w-100">
											<template slot="no-options">Sin pollos encontradas</template>

											<template slot="option" slot-scope="option">
												<div class="d-center">
													{{ option.correlative }}
												</div>
											</template>
											<template slot="selected-option" slot-scope="option">
												<div class="selected d-center">
													{{ option.correlative }}
												</div>
											</template>
										</v-select>
										<v-select v-if="item.type_rival == 3" class="w-100 bg-white"
											v-model="item.rival" :clearable="false" label="name" :options="rivals" />
										<p class="mb-0" v-if="!item.type_rival">Sin seleccionar categoria</p>
									</b-input-group>
								</b-form-group>
								<small class="text-input-error" v-if="errors[0]">{{ errors[0] }}</small>
							</ValidationProvider>
						</b-col>
						<b-col cols="1" class="body-table" style="align-items: center !important">
							<div class="d-flex flex-column justify-center">
								<b-button class="" variant="danger" size="sm" @click="removeSelected(index)">
									<FeatherIcon icon="XIcon" class="x-icon-row text-white" />
								</b-button>
							</div>
						</b-col>
					</b-row>
				</ValidationObserver>

				<b-row class="container-body-table">
					<b-col cols="11" class="btn-add-tope" style="align-items: center !important"></b-col>
					<b-col cols="1" class="body-table" style="align-items: center !important">
						<div class="d-flex flex-column justify-center">
							<b-button class="mt-1 mb-1" variant="success" size="sm" @click="addRow">
								<FeatherIcon icon="PlusIcon" class="x-icon-row text-white" />
							</b-button>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-overlay>
		<template #modal-footer>
			<b-button @click="insertTopes" variant="success">Guardar</b-button>
		</template>
		<!-- <b-modal title="Seleccione un ejemplar" centered hide-footer size="" v-model="creationModal.active">
			<b-row>
				<b-col cols="6" v-for="specimen in creationModal.specimenOpts" :key="specimen.id">
					<div
						style="background: #d9d9da; padding: 0.5rem 0; cursor: pointer"
						class="mb-1"
						:class="{ alreadySelected: specimen.is_training }"
						@click="selectSpecimen(specimen)"
					>
						<SpecimenPlate :specimen="specimen" style="pointer-events: none" />
					</div>
				</b-col>
			</b-row>
		</b-modal> -->
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import settingsService from "@/services/preparacion/settings.service"
import rivalsService from "@/services/rivals.service"
import { mapActions } from "vuex"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import moment from "moment"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object, // Preparation season
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		arrayTypes: [
			{
				text: "Interno",
				value: 1,
			},
			{
				text: "Externo",
				value: 2,
			},
		],
		specimens: [],
		chicks: [],
		rivals: [],
		topes: [
			{
				specimenLocal: null,
				specimen: null,
				chick: null,
				rival: null,
				type_id: 1,
				type_rival: 2,
				search: "",
			},
		],
	}),
	methods: {
		...mapActions({
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		handleHidden() {
			this.isActive = false
			this.search = ""
			this.selectedSpecimens = []
			this.topes = [
				{
					specimenLocal: null,
					specimen: null,
					chick: null,
					rival: null,
					type_id: 1,
					type_rival: 2,
					search: "",
				},
			]
			this.$emit("closing")
		},

		removeSelected(index) {
			if (this.topes.length == 1) {
				this.showToast("warning", "top-right", "Agregar tope", "InfoIcon", "Debe haber al menos un tope")
				return
			}
			this.topes.splice(index, 1)
		},
		async insertTopes() {
			if (!(await this.$refs.formAddTopes.validate())) {
				this.showToast("warning", "top-right", "Campos vacíos", "InfoIcon", "Debe llenar todos los campos")
				return
			}

			this.isLoading = true

			let names = this.topes
				.map((it) => it.specimenLocal)
				.map((specimen) => specimen.alias || specimen.plate)
				.join(", ")

			console.log("names", this.topes, names)
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se agregarán topes a los ejemplares <span class="font-weight-bolder">${names}</span>.</p>
				`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}

			await preparacionService.insertTope(this.topes)
			await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()
			this.isLoading = false
			this.clearTopesForm()
			this.$emit("reload")
			this.handleHidden()
		},
		changeType(index) {
			this.topes[index].type_rival = null
			this.topes[index].rival = null
			this.topes[index].chick = null
			this.topes[index].search = ""
		},
		changeCategory(index) {
			this.topes[index].rival = null
			this.topes[index].chick = null
			this.topes[index].specimen = ""
			this.topes[index].search = ""
		},
		async getRivals({ search, type_rival, loading }) {
			loading(true)
			if (search.length > 1) {
				let { data } = await preparacionService.getRivals({
					type_rival: type_rival,
					search: search,
				})
				type_rival == 1 && (this.specimens = data)
				type_rival == 2 && (this.chicks = data)
			}
			loading(false)
		},
		async getBreeds() {
			const data = await rivalsService.getRivals()
			this.rivals = data
			// console.log("rivals", data)
		},
		addRow() {
			this.topes.push({
				specimenLocal: null,
				specimen: null,
				chick: null,
				rival: null,
				type_id: 1,
				type_rival: 2,
				search: "",
			})
		},
		clearTopesForm() {
			this.topes = [
				{
					specimenLocal: null,
					specimen: null,
					chick: null,
					rival: null,
					type_id: null,
					type_rival: null,
					search: "",
				},
			]
		},
		selectSpecimen(specimen, index, type) {
			// this.topes.forEach((it) => {
			// 	if (it.specimenLocal == specimen) {
			// 		it.specimenLocal = null
			// 	}
			// })
			// console.log("specimen", specimen)
			console.log("index", index, specimen, type)

			if (this.topes[index].type_rival == null) {
				// this.showToast("warning", "top-right", "Campos vacíos", "InfoIcon", "Debe seleccionar un tipo")
				this.specimens = []
				return
			}

			if (type == "local") {
				if (this.topes[index].specimen && this.topes[index].specimen.id == specimen.id) {
					this.topes[index].specimenLocal = null
					this.showToast("warning", "top-right", "Campos vacíos", "InfoIcon", "El ejemplar ya fue elegido")
				}
			} else {
				if (this.topes[index].specimenLocal && this.topes[index].specimenLocal.id == specimen.id) {
					this.topes[index].specimen = null
					this.showToast("warning", "top-right", "Campos vacíos", "InfoIcon", "El ejemplar ya fue elegido")
				}
			}

			this.specimens = []
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
				this.getBreeds()
			}
		},
	},
}
</script>

<style scoped lang="scss">
.container-header-table {
	background: rgba($color: #7367f0, $alpha: 0.2);
	border: 1px solid #7367f0;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;

	.header-table {
		border-right: 1px solid #7367f0;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5rem 0;
		color: #7367f0;
		height: 100%;

		p {
			font-weight: bolder;
		}

		&:last-child {
			border-right: none;
		}
	}
}

.container-body-table {
	.body-table {
		border-right: 1px solid #d9d9da;
		border-bottom: 1px solid #d9d9da;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;

		&:first-child {
			border-left: 1px solid #d9d9da;
		}
	}

	.btn-add-tope {
		border-right: 1px solid #d9d9da;
	}
}

.form-group {
	margin-bottom: 0 !important;
}

.text-input-error {
	color: red;
	font-size: 10px;
	line-height: 0.8;
}
</style>
