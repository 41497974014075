<template>
	<b-modal no-close-on-backdrop centered :title="titleModal" size="xlg" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-row class="gap-y-2">
				<b-col cols="12" md="12" xl="4" class="d-flex align-items-stretch rightLine mt-3">
					<div class="area" ref="areaDiv">
						<input type="file" id="upload" ref="areaInput" accept="video/*" @change="fileHandler" />
						<div style="position: absolute; top: 75%; left: 50%; transform: translate(-50%, -50%)">
							<h4 class="text-primary font-weight-bolder" v-if="!form.videoName">Subir video</h4>
							<span class="text-primary" v-else>Seleccionado: {{ form.videoName }}</span>
						</div>
					</div>
				</b-col>
				<b-col cols="12" md="12" xl="8">
					<div class="d-flex justify-content-end mb-1">
						<b-form-radio-group v-model="form.topeType" @change="typeHelper" buttons>
							<b-form-radio
								:button-variant="form.topeType == 1 ? 'primary' : 'outline-primary'"
								:value="1"
							>
								Pelea Interna
							</b-form-radio>
							<b-form-radio
								:button-variant="form.topeType == 2 ? 'primary' : 'outline-primary'"
								:value="2"
							>
								Pelea Externa
							</b-form-radio>
						</b-form-radio-group>
					</div>
					<b-row class="gap-y-2">
						<b-col cols="12" lg="6">
							<div class="p-1 bgSuperLight rounded-lg h-100 d-flex flex-column justify-content-between">
								<div class="d-flex flex-column align-items-start" style="height: 7rem">
									<h2 class="text-primary font-weight-bolder">Local</h2>
									<SpecimenPlate :specimen="info.specimen" v-if="info && info.specimen" />
								</div>
								<div>
									<h2 class="text-primary font-weight-bolder">Calificaciones</h2>
									<div class="d-flex mb-1">
										<div class="ratingContainer">
											<star-rating
												active-color="#7367f0"
												inactive-color="#000"
												:increment="0.5"
												:star-size="30"
												v-model="form.rating"
											/>
										</div>
									</div>
									<b-form-group label="Cualidades">
										<v-select
											class="bg-white"
											multiple
											label="text"
											:options="qualityOpts"
											:reduce="(opt) => opt.value"
											v-model="form.quality"
										/>
									</b-form-group>
									<b-form-group label="Defectos">
										<v-select
											class="bg-white"
											multiple
											label="text"
											:options="defectOpts"
											:reduce="(opt) => opt.value"
											v-model="form.defect"
										/>
									</b-form-group>
								</div>
							</div>
						</b-col>
						<b-col cols="12" lg="6">
							<div class="p-1 bgSuperLight rounded-lg">
								<div class="d-flex flex-column align-items-start" style="height: 7rem">
									<div class="d-flex align-items-center justify-content-between w-100">
										<h2 class="text-primary font-weight-bolder">Rival</h2>
										<div class="d-flex align-items-center gap-x-2 mb-1">
											<span>Tipo:</span>
											<b-form-radio-group v-model="form.rivalType" @change="oponentTypeHelper">
												<template v-if="form.topeType == 1">
													<b-form-radio :value="1">Ejemplar</b-form-radio>
													<b-form-radio :value="2">Pollo</b-form-radio>
												</template>
												<b-form-radio :value="3" v-else>Rival</b-form-radio>
											</b-form-radio-group>
										</div>
									</div>
									<SpecimenPlate :specimen="form.rival" v-if="form.rival && !!form.rival.plate" />
									<b-badge variant="primary" v-else-if="form.rival && !!form.rival.correlative">
										{{ form.rival.correlative }}
									</b-badge>
									<b-badge variant="info" v-else-if="form.rival && !!form.rival.name">
										{{ form.rival.name }}
									</b-badge>
									<span v-else>Sin seleccionar</span>
								</div>
								<!--  -->

								<b-form-group
									class="mb-1 mt-3 mt-md-1"
									label="Buscar"
									label-class="font-weight-bolder"
									v-if="form.rivalType != 3"
								>
									<b-input-group>
										<b-form-input
											placeholder="Ingrese placa o alias"
											v-model="rivalSearch"
											:disabled="!!!form.rivalType"
											@keyup.enter="getRivals"
										/>
										<b-input-group-append>
											<b-button
												class="btn-icon"
												variant="primary"
												@click="getRivals"
												:disabled="!!!form.rivalType"
											>
												<feather-icon icon="SearchIcon" />
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
								<b-form-group class="mb-1" label="Seleccione un rival" v-else>
									<b-input-group>
										<v-select
											class="w-100 bg-white"
											v-model="form.rival"
											:clearable="false"
											label="name"
											:options="optionsRival"
											@input="rivalHelper"
										/>
									</b-input-group>
								</b-form-group>
								<!--  -->
								<h2 class="text-primary font-weight-bolder">Calificaciones</h2>
								<div class="d-flex mb-1">
									<div class="ratingContainer">
										<star-rating
											active-color="#7367f0"
											inactive-color="#000"
											:increment="0.5"
											:star-size="30"
											v-model="form.rivalRating"
										/>
									</div>
								</div>

								<b-form-group label="Cualidades">
									<v-select
										class="bg-white"
										multiple
										label="text"
										:options="qualityOpts"
										:reduce="(opt) => opt.value"
										v-model="form.rivalQuality"
									/>
								</b-form-group>
								<b-form-group label="Defectos">
									<v-select
										class="bg-white"
										multiple
										label="text"
										:options="defectOpts"
										:reduce="(opt) => opt.value"
										v-model="form.rivalDefect"
									/>
								</b-form-group>
							</div>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="outline-primary" @click="handleHidden">Cancelar</b-button>
			<b-button variant="primary" @click="send">Guardar</b-button>
		</template>
		<b-modal hide-footer centered title="Selecciona un ejemplar" v-model="rivalsModal">
			<b-row style="max-height: 40rem; overflow-y: auto">
				<b-col cols="6" class="mb-2" v-for="rival in rivalOpts" :key="rival.id">
					<div class="cursor-pointer" @click="selectRival(rival)">
						<div class="d-flex justify-content-center" v-if="rival.correlative">
							<b-badge class="d-block cursor-pointer" variant="primary">
								{{ rival.correlative }}
							</b-badge>
						</div>
						<SpecimenPlate :specimen="rival" style="pointer-events: none" v-else />
					</div>
				</b-col>
			</b-row>
		</b-modal>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import { mapState, mapActions } from "vuex"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		form: {
			topeType: 1,
			rivalType: 1,
			rating: 0,
			quality: null,
			defect: null,
			rival: null,
			rivalId: null,
			rivalRating: 0,
			rivalQuality: null,
			rivalDefect: null,
			video: null,
			videoName: null,
		},
		qualityOpts: [],
		defectOpts: [],
		rivalOpts: [],
		//
		rivalSearch: null,
		rivalsModal: false,
		rivalErr: null,
	}),
	computed: {
		...mapState("video", ["optionsRival"]),
		titleModal() {
			if (this.info == null) return ""
			return `Agregar tope ${this.info.specimen.alias || ""} (${this.info.specimen.plate})`
		},
		ownsRival() {
			//
			if (!this.form.rival) return false
			if (!this.form.rival.training) return true
			return this.currentUser.id == this.form.rival.training.user_id
		},
	},
	methods: {
		...mapActions("video", ["A_GET_RIVALS"]),
		handleHidden() {
			this.isActive = false
			this.form = {
				topeType: 1,
				rivalType: 1,
				rating: 0,
				quality: null,
				defect: null,
				rival: null,
				rivalId: null,
				rivalRating: 0,
				rivalQuality: null,
				rivalDefect: null,
				video: null,
				videoName: null,
			}
			this.rivalOpts = []
			this.rivalSearch = null
			this.rivalErr = null
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const responses = [
				preparacionSettingService.getQualities({ status: 1 }),
				preparacionSettingService.getDefects({ status: 1 }),
			]
			const data = await Promise.all(responses)
			this.qualityOpts = data[0].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.defectOpts = data[1].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.isLoading = false
		},
		async getRivals() {
			//
			this.isLoading = true
			let { data } = await preparacionService.getRivals({
				type_rival: this.form.rivalType,
				search: this.rivalSearch,
			})
			//	exclude current specimen
			data = data.filter((d) => d.id != this.info.specimen.id)
			this.rivalSearch = null
			if (data.length == 0) {
				this.showToast("warning", "top-right", "Sin resultados", "SuccessIcon", "No se encontraron resultados")
				this.isLoading = false
				return
			}
			if (data.length == 1) {
				this.selectRival(data[0])
				this.isLoading = false
				return
			}
			this.rivalOpts = data
			this.rivalsModal = true
			this.isLoading = false
		},
		selectRival(rival) {
			this.rivalErr = null
			this.rivalSearch = null
			this.form.rival = rival
			this.form.rivalId = rival.id
			this.rivalsModal = false
			this.showToast(
				"success",
				"top-right",
				"Preparacion",
				"AlertCircleIcon",
				`Se selecciono el ejemplar ${rival.alias || rival.plate}`
			)
		},
		async send() {
			if (
				// valida si se selecciona alguna cualidad o defecto y el rating es 0
				(((this.form.quality != null && this.form.quality.length != 0) ||
					(this.form.defect != null && this.form.defect.length != 0)) &&
					this.form.rating == 0) ||
				(((this.form.rivalQuality != null && this.form.rivalQuality.length != 0) ||
					(this.form.rivalDefect != null && this.form.rivalDefect.length != 0)) &&
					this.form.rivalRating == 0)
			) {
				this.showToast(
					"danger",
					"top-right",
					"Topes",
					"InfoIcon",
					"La calificación es requerida al registrar cualidades o defectos"
				)
				this.rivalErr = true
				return
			}

			if (!this.form.rivalId) {
				this.showToast("danger", "top-right", "Topes", "InfoIcon", "No hay rival seleccionado")
				this.rivalErr = true
				return
			}

			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se registrara nuevo entrenamiento para el ejemplar <span class="font-weight-bolder">${
						this.info.specimen.alias || this.info.specimen.plate
					}</span></p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}
			const payload = new FormData()
			payload.set("rating_local", this.form.rating)
			payload.set("qualities", this.form.quality)
			payload.set("defects", this.form.defect)
			payload.set("type_rival", this.form.rivalType)
			payload.set("specimen_id", this.info.specimen.id)
			payload.set("rival_id", this.form.rivalId)
			payload.set("rating_rival", this.form.rivalRating)
			payload.set("qualities_rival", this.form.rivalQuality)
			payload.set("defects_rival", this.form.rivalDefect)
			payload.set("training_id", this.info.training_id)
			payload.set("type", this.form.topeType)

			if (this.form.video) {
				payload.set("video", this.form.video)
				this.showToast(
					"success",
					"top-right",
					"Preparacion",
					"AlertCircleIcon",
					`Se esta subiendo el video, espere unos segundos`
				)
			}

			if (this.form.video) {
				const response = await this.uploadVideoLaravel(this.form.video, () => {})
				if (response.statusResponse == 500) {
					this.showToast(
						"danger",
						"top-right",
						"ERROR AL SUBIR VIDEO",
						"AlertCircleIcon",
						"ALGO SALIO MAL, VUELVA A INTENTAR POR FAVOR"
					)
					// this.M_RESET_FORM_VIDEO({ category_idIn: 1, type_idIn: 1 })
					// this.videoCounterUpload = 0
					// this.videoCounterUploadCheck = 0
					this.isLoading = false
					return
				}

				payload.set("name", response.fileName)
				payload.set("path_s3", response.url)
			}

			// Enviar data
			await preparacionService.insertTope(payload)
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		async setTypeTope(e) {
			if (e == 2) {
				this.form.rivalType = 3
				await this.A_GET_RIVALS()
			} else {
				this.form.rivalType = 1
			}
		},
		typeHelper(e) {
			this.form.rival = null
			this.form.rivalId = null
			if (e == 2) {
				this.form.rivalRating = 0
				this.form.rivalQuality = null
				this.form.rivalDefect = null
				this.form.rivalType = 3
			} else {
				this.form.rivalType = 1
			}
		},
		oponentTypeHelper(e) {
			if (e == 2) {
				this.form.rival = null
				this.form.rivalId = null
				this.form.rivalRating = 0
				this.form.rivalQuality = null
				this.form.rivalDefect = null
			}
		},
		rivalHelper(e) {
			this.form.rivalId = e.id
		},
		fileHandler(e) {
			this.form.video = e.target.files[0]
			this.form.videoName = e.target.files[0].name
			this.$refs.areaDiv.style.backgroundImage = `url("https://cdn2.iconfinder.com/data/icons/aami-web-internet/64/aami4-56-512.png")`
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getOpts()
				this.A_GET_RIVALS()
				this.isActive = val
			}
		},
	},
}
</script>

<style lang="scss" scoped>
//
.area {
	width: 100%;
	min-height: 25vh;
	position: relative;
	margin-top: 1rem;
	border: 2px dashed var(--primary);
	border-radius: 1rem;
	background-image: url("https://cdn2.iconfinder.com/data/icons/ios-7-icons/50/upload-512.png");
	background-color: rgba(115, 103, 240, 0.05);
	background-position: center;
	background-repeat: no-repeat;
	background-size: 64px 64px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-align: center;
}

.area input {
	width: 400%;
	height: 100%;
	margin-left: -300%;
	border: none;
	cursor: pointer;
}

.area input:focus {
	outline: none;
}

.area:hover,
.area.dragging,
.area.uploading {
	background-color: rgba(115, 103, 240, 0.1);
}

.area.uploading {
	background: none;
}

//

.videoContainer {
	display: flex;
	align-items: stretch;
	border-right: 1px solid var(--primary);
}

.videoContainer2 {
	display: flex;
	align-items: center;
	padding: 1rem;
	background: rgba(115, 103, 240, 0.05);
	border: 2px dashed var(--primary);
	border-radius: 0.5rem;
}

.bgSuperLight {
	background: rgba(115, 103, 240, 0.05);
}

.ratingContainer {
	padding: 0.5rem;
	background: #fff;
	border-radius: 10rem;
}

.rightLine {
	// border-right: none;
}

@media (min-width: 1200px) {
	.rightLine {
		// border-right: 1px solid var(--primary);
		// padding-top: 1rem;
		// margin-top: 1rem;
	}
}
</style>
