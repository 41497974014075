<template>
	<div>
		<span>
			<feather-icon
				class="cursor-pointer text-danger"
				icon="AwardIcon"
				size="20"
				v-b-tooltip.hover="`Calificar Tope`"
				@click="validActiveToVideoTope"
			/>
		</span>
		<b-modal
			centered
			no-close-on-backdrop
			size="xlg"
			title="Calificar tope"
			v-model="modalReview.show"
			@hidden="handleHidden"
			@show="handleShow"
		>
			<b-overlay :show="modalReview.loading">
				<b-row class="gap-y-2">
					<b-col cols="12" xl="4" class="videoContainer">
						<div class="videoContainer2">
							<div class="w-100">
								<VideoCloudflare :uid="video.uid" v-if="video.status == 2" />
								<h4 class="text-center" v-else>Cargando video</h4>
							</div>
						</div>
					</b-col>
					<b-col class="pt-2" cols="12" xl="8">
						<b-row class="gap-y-2" :align-h="onlyLocal ? 'center' : 'start'">
							<b-col cols="12" lg="6">
								<div class="p-1 bgSuperLight rounded-lg">
									<h2 class="text-primary font-weight-bolder">Local</h2>
									<div class="d-flex justify-content-center align-items-center" style="height: 7rem">
										<SpecimenPlate :specimen="info._curSpecimen" />
									</div>
									<div class="d-flex mb-1">
										<b-row class="d-flex w-full mb-2">
											<b-col cols="8" class="pl-2">
												<veeno
													pipsy
													:connect="[true, false]"
													:handles="[0]"
													:range="{ min: 0, max: 5 }"
													:step="0.1"
													v-model="rating"
												/>
											</b-col>
											<b-col class="text-center text-nowrap text-weight-bolder h4">
												{{ rating }} / 5
											</b-col>
										</b-row>
									</div>
								</div>
							</b-col>
							<b-col cols="12" lg="6" v-if="!onlyLocal && canQualifyRival">
								<div class="p-1 bgSuperLight rounded-lg">
									<h2 class="text-primary font-weight-bolder">Rival</h2>
									<div class="d-flex justify-content-center align-items-center" style="height: 7rem">
										<SpecimenPlate :specimen="info.specimen" />
									</div>
									<div class="d-flex mb-1">
										<b-row class="d-flex w-full mb-2">
											<b-col cols="8" class="pl-2">
												<veeno
													pipsy
													:connect="[true, false]"
													:handles="[0]"
													:range="{ min: 0, max: 5 }"
													:step="0.1"
													v-model="ratingRival"
												/>
											</b-col>
											<b-col class="text-center text-nowrap text-weight-bolder h4">
												{{ ratingRival }} / 5
											</b-col>
										</b-row>
									</div>
								</div>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
			</b-overlay>
			<template #modal-footer>
				<b-button variant="outline-primary" @click="handleHidden">Cancelar</b-button>
				<b-button variant="primary" @click="reviewTope" :disabled="saveDisabled">Guardar</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import VideoCloudflare from "@/views/amg/tournaments/VideoCloudflare.vue"
import preparacionSettingService from "@/services/preparacion/settings.service.js"
import preparacionService from "@/services/preparacion/default.service.js"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import veeno from "veeno"
import "nouislider/distribute/nouislider.min.css"

export default {
	components: {
		VideoCloudflare,
		SpecimenPlate,
		veeno,
	},
	props: {
		training_tope_id: {
			type: Number,
		},
		video: {
			type: Object,
			default: () => {},
		},
		info: Object,
	},
	data() {
		return {
			modalReview: {
				show: false,
				loading: false,
			},
			qualityOpts: [],
			defectOpts: [],
			//
			defects: [],
			qualities: [],
			rating: 0,
			//
			defectsRival: [],
			qualitiesRival: [],
			ratingRival: 0,
			saveDisabled: false,
		}
	},
	computed: {
		canQualifyRival() {
			let hasSpecimen = false
			if (this.info.rival_specimens_score) {
				hasSpecimen = this.info.rival_specimens_score.some((score) => score.id == this.info.specimen.id)
			}
			//
			let hasUser = false
			if (this.info.rival_specimens_score) {
				hasUser = this.info.rival_specimens_score.some((score) => score.user == this.currentUser.id)
			}
			if (hasUser) return false
			//
			let visibleToGuest = false
			if (this.info.training_rival) {
				visibleToGuest = this.info.training_rival.show_guest == 1
			}
			//
			if (this.isAdmin) return true
			if (this.isPreparador) {
				if (!this.info.training_rival) return true
				let hasUser = this.info.training_rival.user == this.currentUser.id
				return hasUser
			}
			if (this.isInvitado) {
				return visibleToGuest
			}
			// if (!this.info.rival_specimens_score) return true;
			// let hasSpecimen = this.info.rival_specimens_score.some((score) => score.id == this.info.specimen.id);
			// let hasUser = this.info.rival_specimens_score.some((score) => score.user == this.currentUser.id);
			return hasSpecimen && visibleToGuest
		},
		onlyLocal() {
			if (this.info.specimen && this.info.specimen.plate) return false
			return true
		},
	},
	methods: {
		handleHidden() {
			this.defects = []
			this.qualities = []
			this.rating = 0
			this.defectsRival = []
			this.qualitiesRival = []
			this.ratingRival = 0
			this.modalReview.show = false
		},
		handleShow() {
			if (this.isAdmin) {
				this.qualify = true
			}
		},
		async getOpts() {
			this.isLoading = true
			const responses = [
				preparacionSettingService.getQualities({ status: 1 }),
				preparacionSettingService.getDefects({ status: 1 }),
			]
			const data = await Promise.all(responses)
			this.qualityOpts = data[0].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.defectOpts = data[1].data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.isLoading = false
		},
		async reviewTope() {
			if (this.rating == 0) {
				this.showToast(
					"warning",
					"top-right",
					"Sin resultados",
					"AlertCircleIcon",
					"No se asigno una calificacion para el especimen local"
				)
				return
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return
			this.saveDisabled = true
			this.modalReview.loading = true
			let body = {}
			body.qualities = this.qualities
			body.defects = this.defects
			body.rating_local = this.rating
			body.qualities_rival = this.qualitiesRival
			body.defects_rival = this.defectsRival
			body.rating_rival = this.ratingRival
			await preparacionService.insertReviewTope({
				specimen_id: this.info._curSpecimen.id,
				rival_id: this.info.specimen ? this.info.specimen.id : null,
				confrontation_id: this.info.confrontation_id,
				...body,
			})
			this.saveDisabled = false
			this.modalReview.loading = false
			this.modalReview.show = false
			this.$emit("refresh")
		},
		openModal() {
			this.modalReview.show = true
			this.getOpts()
		},
		async validActiveToVideoTope() {
			if (this.training_tope_id == null && this.video.status == 2) {
				this.openModal()
				return
			}

			if (!this.training_tope_id) {
				this.openModal()
				return
			}

			this.isPreloading()
			const {
				data: { valid },
			} = await preparacionService.validActiveToVideoTope({ id: this.training_tope_id })
			this.isPreloading(false)

			if (valid) {
				this.openModal()
				this.video.status = 2
			} else {
				this.showToast(
					"warning",
					"top-right",
					"Preparación",
					"WarningIcon",
					"El video estará disponible en unos momentos"
				)
			}
		},
	},
}
</script>

<style scoped>
.videoContainer {
	display: flex;
	align-items: stretch;
	border-right: none;
}

.videoContainer2 {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 1rem;
	background: rgba(115, 103, 240, 0.05);
	border: 2px dashed var(--primary);
	border-radius: 0.5rem;
}

.bgSuperLight {
	background: rgba(115, 103, 240, 0.05);
}

@media (min-width: 1200px) {
	.videoContainer {
		border-right: 1px solid var(--primary);
	}
}
</style>
