<template>
	<b-modal
		centered
		:title="title"
		:size="info && info._a == 'track' ? 'lg' : 'md'"
		:hide-footer="isTracking"
		v-model="isActive"
		@hidden="handleHidden"
		no-close-on-backdrop
	>
		<b-overlay :show="isLoading" v-if="!!info">
			<b-table-simple v-if="info._a == 'track'">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Creado por</b-th>
						<b-th>Fecha</b-th>
						<b-th>Estado</b-th>
						<b-th v-if="[3, 5].includes(info._status)">Motivo</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.created_by.date">
						<b-td>{{ row.created_by.user }}</b-td>
						<b-td>{{ row.created_by.date | myGlobalDayShort }}</b-td>
						<b-td>
							<b-badge :variant="colorizeStatus(row.status_id)">{{ row.status_name }}</b-badge>
						</b-td>
						<b-td v-if="[3, 5].includes(info._status)">{{ row.name || "---" }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<ValidationObserver ref="form" v-else-if="info._a == 'cancel'">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group label="Motivo">
						<b-form-select :class="errors[0] ? 'is-invalid' : ''" v-model="reason" :options="reasonOpts" />
					</b-form-group>
					<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
				</ValidationProvider>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group class="mb-0" label="Nuevo estado">
						<!-- <b-form-select  v-model="" :options="statusOpts" /> -->

						<v-select
							:class="errors[0] ? 'is-invalid' : ''"
							v-model="status"
							:options="statusOpts"
							label="text"
							class="w-100"
							:reduce="(val) => val.value"
							placeholder="Seleccionar opción"
							:selectable="(val) => val.selectable"
							:state="errors[0] ? false : null"
							:disabled="isInventory ? !specimen.check : false"
						/>
					</b-form-group>
					<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
				</ValidationProvider>
			</ValidationObserver>
			<ValidationObserver ref="form" v-else-if="info._a == 'finish'">
				<b-row>
					<b-col>
						<ValidationProvider rules="required" v-slot="{ errors }">
							<b-form-group class="mb-0" label="Kilos">
								<b-form-input :class="errors[0] ? 'is-invalid' : ''" v-model="kilos" type="number" />
							</b-form-group>
							<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
						</ValidationProvider>
					</b-col>
					<b-col>
						<b-form-group class="mb-0" label="Libras">
							<b-form-input :value="kilosToPounds" type="number" disabled />
						</b-form-group>
					</b-col>
				</b-row>
				<!-- <ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group class="mb-0 mt-1" label="Foto">
						<b-form-file :class="errors[0] ? 'is-invalid' : ''" v-model="image" accept="image/*" />
					</b-form-group>
					<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
				</ValidationProvider> -->
			</ValidationObserver>
			<ValidationObserver ref="form" v-else-if="info._a == 'change'">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group class="mb-0" label="Bloque">
						<b-form-select :class="errors[0] ? 'is-invalid' : ''" v-model="block" :options="blockOpts" />
					</b-form-group>
					<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
				</ValidationProvider>
			</ValidationObserver>
			<ValidationObserver ref="form" v-else-if="info._a == 'inactive'">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group label="Motivo">
						<b-form-select :class="errors[0] ? 'is-invalid' : ''" v-model="reason" :options="reasonOpts" />
					</b-form-group>
					<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
				</ValidationProvider>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="toFinished" v-if="info._a == 'finish'">Actualizar</b-button>
			<b-button variant="success" @click="toCanceled" v-else-if="info._a == 'cancel'">Actualizar</b-button>
			<b-button variant="success" @click="changeBlock" v-else-if="info._a == 'change'">Actualizar</b-button>
			<b-button variant="success" @click="toInactive" v-if="info._a == 'inactive'">Actualizar</b-button>
		</template>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		kilos: null,
		reason: null,
		status: null,
		image: null,
		block: null,
		reasonOpts: [],
		statusOpts: [],
		blockOpts: [],
		rows: [],
	}),
	computed: {
		isTracking() {
			if (this.info == null) return false
			return this.info._a == "track"
		},
		title() {
			if (this.info == null) return ""
			switch (this.info._a) {
				case "finish":
					return `Finalizar preparacion del ejemplar ${this.info.specimen.alias || this.info.specimen.plate}`
				case "cancel":
					return `Cancelar preparacion del ejemplar ${this.info.specimen.alias || this.info.specimen.plate}`
				case "change":
					return `Cambiar de bloque preparacion del ejemplar ${
						this.info.specimen.alias || this.info.specimen.plate
					}`
				case "track":
					return `Tracking del ejemplar ${this.info.specimen.alias || this.info.specimen.plate}`
				case "inactive":
					return `Inactivar preparacion del ejemplar ${this.info.specimen.alias || this.info.specimen.plate}`
			}
		},
		kilosToPounds() {
			return (this.kilos * 2.2046).toFixed(2)
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.reason = null
			this.status = null
			this.kilos = null
			this.image = null
			this.block = null
			this.reasonOpts = []
			this.statusOpts = []
			this.blockOpts = []
			this.rows = []
			this.$emit("closing")
		},
		async getTracking() {
			this.isLoading = true
			const { data } = await preparacionService.getTrackingPreparation({ training_id: this.info.training_id })
			this.rows = data
			this.isLoading = false
		},
		async getOpts() {
			this.isLoading = true
			await this.$store.dispatch({
				type: "statusSpecimens/getAllStatusSpecimens",
				category_id: this.info.specimen.category_id,
			})
			this.statusOpts = this.$store.state.statusSpecimens.arrayStatus.map((opt) => ({
				value: opt.id,
				text: `------ ${opt.description}`,
				selectable: true,
				in_galpon: opt.in_galpon,
			}))

			// Status
			this.statusOpts.unshift({
				value: null,
				text: "DISPONIBLES",
				selectable: false,
			})

			const indexInactivos = this.statusOpts.findIndex((it) => it.in_galpon == 0)

			// console.log("index", indexInactivos)

			this.statusOpts.splice(indexInactivos, 0, {
				value: null,
				text: "NO DISPONIBLES",
				selectable: false,
			})

			const { data } = await preparacionSettingService.getReasons({ status: 1 })
			this.reasonOpts = data.map((opt) => ({
				value: opt.id,
				text: opt.name,
			}))
			const { data: blocks } = await preparacionSettingService.getBlocks({ season_id: this.info._season.id })
			this.blockOpts = blocks.map((block) => ({
				text: block.name,
				value: block.training_block_id,
			}))
			this.isLoading = false
		},
		async changeBlock() {
			if (!(await this.$refs.form.validate())) return
			this.isLoading = true
			let newBlock = this.blockOpts.find((block) => block.value == this.block)
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se pasara el ejemplar <span class="font-weight-bolder">${
						this.info.specimen.alias || this.info.specimen.plate
					}</span>
						al bloque <span class="font-weight-bolder">${newBlock.text}</span>
						</p>
				`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				this.handleHidden()
				return
			}
			await preparacionService.changeBlock({ training_id: this.info.training_id, block_id: this.block })
			this.isLoading = false
			this.$emit("refresh")
			this.handleHidden()
		},
		async toFinished() {
			if (!(await this.$refs.form.validate())) return
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `<p class="mb-0">Se cambiara estado de preparacion del ejemplar <b>${
					this.info.specimen.alias || this.info.specimen.plate
				}</b> a <b>LISTO</b>.</p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				this.handleHidden()
				return
			}
			let form = new FormData()
			form.append("training_id", this.info.training_id)
			form.append("status_id", 2)
			form.append("weight", this.kilos)
			form.append("image", this.image)
			await preparacionService.changeStatus(form)
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		async toCanceled() {
			if (!(await this.$refs.form.validate())) return
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `<p class="mb-0">Se cambiara estado de preparacion del ejemplar <b>${
					this.info.specimen.alias || this.info.specimen.plate
				}</b> a <b>CANCELADO</b>.</p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				this.handleHidden()
				return
			}
			let form = new FormData()
			form.append("training_id", this.info.training_id)
			form.append("status_id", 3)
			form.append("reason_id", this.reason)
			form.append("status_specimen_id", this.status)
			await preparacionService.changeStatus(form)
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		async toInProcess() {
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `<p class="mb-0">Se cambiara estado de preparacion del ejemplar <b>${
					this.info.specimen.alias || this.info.specimen.plate
				}</b> a <b>EN PROCESO</b>.</p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				this.handleHidden()
				return
			}
			let form = new FormData()
			form.append("training_id", this.info.training_id)
			form.append("status_id", 1)
			await preparacionService.changeStatus(form)
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		async toInactive() {
			if (!(await this.$refs.form.validate())) return
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `<p class="mb-0">Se cambiara estado de preparacion del ejemplar <b>${
					this.info.specimen.alias || this.info.specimen.plate
				}</b> a <b>INACTIVO</b>.</p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				this.handleHidden()
				return
			}
			let form = new FormData()
			form.append("training_id", this.info.training_id)
			form.append("status_id", 5)
			form.append("reason_id", this.reason)
			await preparacionService.changeStatus(form)
			this.isLoading = false
			this.handleHidden()
			this.$emit("refresh")
		},
		colorizeStatus(status) {
			switch (status) {
				case 1:
					return "success"
				case 2:
					return "primary"
				case 3:
					return "danger"
				default:
					return "secondary"
			}
		},
	},
	watch: {
		show(val) {
			if (val) {
				if (this.info._a == "return") {
					this.toInProcess()
					return
				}
				if (this.isTracking) this.getTracking()
				else this.getOpts()
				this.isActive = val
			}
		},
	},
}
</script>
