<template>
	<b-modal centered size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<div class="container-table">
					<b-table-simple>
						<b-thead class="text-center">
							<b-tr>
								<b-th>EJEMPLAR</b-th>
								<b-th style="min-width: 150px !important">Kilos</b-th>
								<b-th style="min-width: 150px !important">Libras</b-th>
								<b-th v-if="isMultiple">Acciones</b-th>
							</b-tr>
						</b-thead>
						<b-tbody class="text-center">
							<b-tr v-for="row in rows" :key="row.training_id">
								<b-td
									><b-badge>{{ row.specimen.alias || row.specimen.plate }}</b-badge></b-td
								>
								<b-td>
									<ValidationProvider
										:rules="!!row.weight_last ? '' : 'required'"
										v-slot="{ errors }"
									>
										<div>
											<b-form-input
												:class="errors[0] ? 'is-invalid' : ''"
												type="number"
												v-model.number="row._kilos"
												:disabled="!!row.weight_last"
											/>
										</div>
									</ValidationProvider>
									<small class="text-warning" v-if="!!row.weight_last">Ya tiene peso asignado</small>
								</b-td>
								<b-td><b-form-input :value="toPounds(row._kilos)" disabled /></b-td>
								<td v-if="isMultiple">
									<!-- <b-button @click="deleteSpecimenSelected(row)">eliminar</b-button> -->
									<b-button
										class="btn-icon"
										variant="flat-danger"
										size="sm"
										@click="deleteSpecimenSelected(row)"
									>
										<feather-icon class="cursor-pointer" size="20" icon="TrashIcon" />
									</b-button>
								</td>
							</b-tr>
						</b-tbody>
					</b-table-simple>
				</div>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<!-- <b-button variant="warning" @click="clearAll" v-if="isMultiple">Limpiar</b-button> -->
			<b-button variant="success" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js";

export default {
	props: {
		show: Boolean,
		info: Array,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [],
	}),
	computed: {
		isMultiple() {
			return this.rows.length > 1;
		},
		title() {
			if (!this.rows[0]) return;
			if (this.isMultiple) return "Registrar peso masivamente";
			return `Registrar peso al ejemplar ${this.rows[0].specimen.alias || this.rows[0].specimen.plate}`;
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.isLoading = false;
			this.rows = [];
			this.$emit("closing");
		},
		async save() {
			if (!(await this.$refs.form.validate())) return;
			this.isLoading = true;
			let html;
			if (this.isMultiple) {
				let filtered = this.rows
					.filter((row) => !row.weight_last)
					.map((row) => row.specimen.alias || row.specimen.plate)
					.join(", ");
				html = `
					<p class="mb-0">Se registrará peso para los ejemplares <span class="font-weight-bolder">${filtered}</span>.</p>
				`;
			} else {
				html = `
					<p class="mb-0">Se registrará un nuevo peso al ejemplar <span class="font-weight-bolder">${
						this.rows[0].specimen.alias || this.rows[0].specimen.plate
					}</span></p>
				`;
			}
			const { isConfirmed } = await this.showConfirmSwal({ html });
			if (!isConfirmed) {
				this.isLoading = false;
				return;
			}
			const specimens = this.rows
				.map((row) => ({
					id: row.specimen.id,
					weight: row._kilos,
				}))
				.filter((row) => row.weight != null);
			await preparacionService.insertWeight({ specimens });
			this.isLoading = false;
			this.$emit("refresh");
			this.handleHidden();
		},
		toPounds(num) {
			return (num * 2.2046).toFixed(2);
		},
		deleteSpecimenSelected(row) {
			this.rows.forEach((element, index) => {
				if (element.specimen.id == row.specimen.id) {
					this.$emit("cleanCheck", row.specimen.id);
					this.rows.splice(index, 1);
				}
			});
			if (this.rows.length == 0) {
				this.handleHidden();
			}
		},
		async clearAll() {
			const { isConfirmed } = await this.showConfirmSwal({
				text: "Se limpiaran todos los ejemplares seleccionados",
			});
			if (!isConfirmed) return;
			this.rows.forEach((row) => (row._kilos = null));
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val;
				this.rows = this.info.map((row) => ({
					...row,
					_kilos: null,
				}));
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.container-table {
	overflow-x: auto;
}
</style>
