<template>
	<div>
		<span>
			<feather-icon
				class="cursor-pointer text-primary"
				@click="openModal"
				icon="PlusIcon"
				style="margin: 0 0 4px 4px"
				v-b-tooltip.hover
				title="Subir video"
			/>
		</span>

		<b-modal centered no-close-on-backdrop hide-footer size="sm" :title="`Subir video / ${title}`" v-model="open">
			<b-overlay :show="isLoading">
				<ValidationObserver ref="form">
					<ValidationProvider rules="required" v-slot="{ errors }">
						<b-form-group class="mt-1" label="Video">
							<b-form-file accept="video/*" v-model="video" />
						</b-form-group>
					</ValidationProvider>
				</ValidationObserver>
				<div class="d-flex justify-content-end">
					<button @click="send" class="btn btn-primary">Guardar</button>
				</div>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import videosService from "@/services/videos.service"

export default {
	name: "UploadVideoTope",
	props: {
		title: {
			type: String,
		},
		info: Object,
	},
	data() {
		return {
			open: false,
			video: null,
			isLoading: false,
		}
	},
	methods: {
		openModal() {
			this.open = true
		},
		async send() {
			if (!(await this.$refs.form.validate())) return

			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se registrara nuevo video para el ejemplar <span class="font-weight-bolder">${this.title}</span></p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}

			const response = await this.uploadVideoLaravel(this.video, () => {})

			if (response.statusResponse == 500) {
				this.showToast(
					"danger",
					"top-right",
					"ERROR AL SUBIR VIDEO",
					"AlertCircleIcon",
					"ALGO SALIO MAL, VUELVA A INTENTAR POR FAVOR"
				)
				this.isLoading = false
				return
			}

			const payload = new FormData()
			payload.set("id", this.info.id)
			payload.set("name", response.fileName)

			if (this.video) payload.set("path_s3", response.url)


			// return;

			this.showToast(
				"success",
				"top-right",
				"Preparacion",
				"AlertCircleIcon",
				`Se esta subiendo el video, espere unos segundos`
			)
			// await preparacionService.insertTope(payload);

			const { status, message } = await preparacionService.storeVideo(payload)


			if (status) {
				this.isLoading = false
				this.open = false
				this.$emit("refresh")
			} else {
				setTimeout(() => {
					this.isLoading = false
					this.open = false
					this.$emit("refresh")
					this.showToast(
						"danger",
						"top-right",
						"Preparacion",
						"AlertCircleIcon",
						`No se pudo subir el video de forma correcta.`
					)
				}, 1200)
			}
			// this.handleHidden();
		},
	},
}
</script>
