<template>
	<b-modal no-close-on-backdrop centered :title="titleModal" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<ValidationObserver ref="form">
				<b-row>
					<b-col>
						<b-form-group label="1. Tiempo (minutos)">
							<vue-number-input v-model="form.minutes" :min="1" :max="20" controls />
						</b-form-group>
					</b-col>
					<b-col>
						<b-form-group>
							<template #label>2. Calificacion</template>
							<StarRating active-color="#7367f0" :increment="0.5" :star-size="35" v-model="form.rating" />
						</b-form-group>
					</b-col>
				</b-row>
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group class="mb-0" label="3. Ejercicio(s)">
						<v-select
							multiple
							class="w-100"
							v-model="form.exercice"
							:clearable="false"
							label="text"
							:options="exerciceOpts"
							:reduce="(option) => option.value"
						/>
					</b-form-group>
					<small class="text-danger" v-if="!!errors[0]">{{ errors[0] }}</small>
				</ValidationProvider>
				<b-form-group class="mt-1" label="4. Comentario">
					<b-form-textarea v-model="form.comment" />
				</b-form-group>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="send">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js"
import preparacionSettingService from "@/services/preparacion/settings.service.js"

import StarRating from "vue-star-rating"

export default {
	components: { StarRating },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		form: {
			minutes: 10,
			rating: 0,
			ratingActive: false,
			comment: "",
			exercice: null,
		},
		exerciceOpts: [],
	}),
	computed: {
		titleModal() {
			return this.info
				? `Registrar entrenamiento / ${this.info.specimen.alias ? this.info.specimen.alias : ""} (${
						this.info.specimen.plate
				  })`
				: ""
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.form = {
				minutes: 10,
				rating: 0,
				ratingActive: false,
				comment: "",
				exercice: null,
			}
			this.$emit("closing")
		},
		async getOpts() {
			this.isLoading = true
			const { data } = await preparacionSettingService.getExercices({ status: 1 })
			this.exerciceOpts = data.map((opt) => ({ value: opt.id, text: opt.name }))
			this.isLoading = false
		},
		async send() {
			if (!(await this.$refs.form.validate())) return

			if (this.form.rating == 0) {
				return this.showToast(
					"warning",
					"top-right",
					"Preparación",
					"WarningIcon",
					"Se debe seleccionar una calificacion"
				)
			}
			this.isLoading = true
			const { isConfirmed } = await this.showConfirmSwal({
				html: `
					<p class="mb-0">Se registrará nuevo entrenamiento para el ejemplar <span class="font-weight-bolder">${
						this.info.specimen.alias || this.info.specimen.plate
					}</span></p>`,
			})
			if (!isConfirmed) {
				this.isLoading = false
				return
			}

			const { message } = await preparacionService.insertRoutine({
				time: this.form.minutes,
				rating: this.form.rating,
				comment: this.form.comment,
				training_id: this.info.training_id,
				training_physical: this.form.exercice,
			})
			this.isLoading = false
			this.$emit("refresh")
			this.showToast("success", "top-right", "Preparación", "SuccessIcon", message)
			this.handleHidden()
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getOpts()
				this.isActive = val
			}
		},
	},
}
</script>
