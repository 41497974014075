var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","title":_vm.title,"size":_vm.info && _vm.info._a == 'track' ? 'lg' : 'md',"hide-footer":_vm.isTracking,"no-close-on-backdrop":""},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(_vm.info._a == 'finish')?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.toFinished}},[_vm._v("Actualizar")]):(_vm.info._a == 'cancel')?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.toCanceled}},[_vm._v("Actualizar")]):(_vm.info._a == 'change')?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.changeBlock}},[_vm._v("Actualizar")]):_vm._e(),(_vm.info._a == 'inactive')?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.toInactive}},[_vm._v("Actualizar")]):_vm._e()]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[(!!_vm.info)?_c('b-overlay',{attrs:{"show":_vm.isLoading}},[(_vm.info._a == 'track')?_c('b-table-simple',[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',[_vm._v("Creado por")]),_c('b-th',[_vm._v("Fecha")]),_c('b-th',[_vm._v("Estado")]),([3, 5].includes(_vm.info._status))?_c('b-th',[_vm._v("Motivo")]):_vm._e()],1)],1),_c('b-tbody',{staticClass:"text-center"},_vm._l((_vm.rows),function(row){return _c('b-tr',{key:row.created_by.date},[_c('b-td',[_vm._v(_vm._s(row.created_by.user))]),_c('b-td',[_vm._v(_vm._s(_vm._f("myGlobalDayShort")(row.created_by.date)))]),_c('b-td',[_c('b-badge',{attrs:{"variant":_vm.colorizeStatus(row.status_id)}},[_vm._v(_vm._s(row.status_name))])],1),([3, 5].includes(_vm.info._status))?_c('b-td',[_vm._v(_vm._s(row.name || "---"))]):_vm._e()],1)}),1)],1):(_vm.info._a == 'cancel')?_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motivo"}},[_c('b-form-select',{class:errors[0] ? 'is-invalid' : '',attrs:{"options":_vm.reasonOpts},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),(!!errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3792617710)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Nuevo estado"}},[_c('v-select',{staticClass:"w-100",class:errors[0] ? 'is-invalid' : '',attrs:{"options":_vm.statusOpts,"label":"text","reduce":function (val) { return val.value; },"placeholder":"Seleccionar opción","selectable":function (val) { return val.selectable; },"state":errors[0] ? false : null,"disabled":_vm.isInventory ? !_vm.specimen.check : false},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(!!errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,1822142716)})],1):(_vm.info._a == 'finish')?_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Kilos"}},[_c('b-form-input',{class:errors[0] ? 'is-invalid' : '',attrs:{"type":"number"},model:{value:(_vm.kilos),callback:function ($$v) {_vm.kilos=$$v},expression:"kilos"}})],1),(!!errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,2139265463)})],1),_c('b-col',[_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Libras"}},[_c('b-form-input',{attrs:{"value":_vm.kilosToPounds,"type":"number","disabled":""}})],1)],1)],1)],1):(_vm.info._a == 'change')?_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Bloque"}},[_c('b-form-select',{class:errors[0] ? 'is-invalid' : '',attrs:{"options":_vm.blockOpts},model:{value:(_vm.block),callback:function ($$v) {_vm.block=$$v},expression:"block"}})],1),(!!errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3689932410)})],1):(_vm.info._a == 'inactive')?_c('ValidationObserver',{ref:"form"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Motivo"}},[_c('b-form-select',{class:errors[0] ? 'is-invalid' : '',attrs:{"options":_vm.reasonOpts},model:{value:(_vm.reason),callback:function ($$v) {_vm.reason=$$v},expression:"reason"}})],1),(!!errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3792617710)})],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }