<template>
	<b-modal centered hide-footer :title="titleModal" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<b-table-simple sticky-header="40vh">
				<b-thead class="text-center">
					<b-tr>
						<b-th>Creado por</b-th>
						<b-th>Fecha</b-th>
					</b-tr>
				</b-thead>
				<b-tbody class="text-center">
					<b-tr v-for="row in rows" :key="row.id">
						<b-td> {{ row.user }} </b-td>
						<b-td> {{ row.created_at | myGlobalDayShort }} </b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>
	</b-modal>
</template>

<script>
import preparacionService from "@/services/preparacion/default.service.js";

export default {
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		rows: [1, 2],
		//
	}),
	computed: {
		titleModal() {
			if (!this.curCharacteristic) return "---";
			return `${this.curCharacteristic.name} /  ${
				this.info.specimen.alias || this.info.specimen.plate
			}`;
		},
		curCharacteristic() {
			if (this.info._type == "q") {
				return this.info.tope_last.qualities.find((val) => val.id == this.info._id);
			} else if (this.info._type == "d") {
				return this.info.tope_last.defects.find((val) => val.id == this.info._id);
			}
			return null;
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false;
			this.modalTope = {
				show: false,
				title: "",
				info: {},
			};
			this.$emit("closing");
		},
		async getRows() {
			this.isLoading = true;
			const data = await preparacionService.getTrackingCharacteristic({
				training_id: this.info.training_id,
				characteristics_id: this.curCharacteristic.id,
				type: this.info._type == "q" ? 1 : 2,
			});
			this.rows = data;
			this.isLoading = false;
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getRows();
				this.isActive = val;
			}
		},
	},
};
</script>
